
import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { NavLink } from "react-router-dom";
import { Backdrop, CircularProgress, TableContainer, Paper, Button, Toolbar, Typography, Table, TableHead, TableRow, TableCell, TableBody, Box, Checkbox } from "@mui/material";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import AccountDialog from "../dialogs/AccountDialog";
import Account from "../models/Account";
import Confirm from "../components/Confirm";
import Address from "../models/Address";
import TitleToolbar from "../components/TitleToolbar";
import {accountCache} from "../models/Cache";
import ReactGA from "react-ga4";

const AccountsList : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  
    
  const breadcrumbs = [
    {name: "Accounts", link: ""}];

  const [loading, setLoading] = useState(!siteBionicsApplication.accountList);
    
  useEffect(() => {
    
    if (siteBionicsApplication.accountList === undefined) {
      setLoading(true);
      siteBionicsApplication.loadAccountsAsync().then(() => {        
        setLoading(false);
      });      
    } 

    if (!siteBionicsApplication.currentAccount && siteBionicsApplication.accountList && siteBionicsApplication.accountList.length > 0) {
      siteBionicsApplication.setCurrentAccount(siteBionicsApplication.accountList![0]);
    }
    
  }, [siteBionicsApplication,   siteBionicsApplication.accountList]);
  
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [deletingAccountName, setDeletingAccountName] = useState<string | undefined>(undefined);

  const [busy, setBusy] = useState(false);
    
  const handleAdd = (name: string, address: Address) => {
    setBusy(true);
    siteBionicsApplication.service.createAccountAsync(name, address).then((newAccount: Account) => {

      // Add to the account list
      siteBionicsApplication.accountList!.push(newAccount);

      // Add to the cache
      accountCache.addItem(newAccount);

      // Select the account
      siteBionicsApplication.setCurrentAccount(newAccount);

      event("add", "confirm");

      // Done with the dialog
      setAdding(false);
      setBusy(false);
    });    
  };

  const handleDelete = () => {
    event("delete", "confirm");

    const accountId = siteBionicsApplication.currentAccount!.accountId;

    setBusy(true);
    siteBionicsApplication.service.deleteAccountAsync(accountId).then(() => {      

      // We deleted on the server. We need to clean up references in this app.
      
      // Find the account in the account list
      let index = siteBionicsApplication.accountList?.findIndex(obj => accountId === obj.accountId);

      // If the account is in the account list, remove it
      if (index !== -1) {
        siteBionicsApplication.accountList!.splice(index!, 1);
      }

      // Remove it from the MRU list
      if (siteBionicsApplication.mruData) {
        siteBionicsApplication.mruData.removeAccount(accountId);
      }

      // Remove the account from the cache
      accountCache.removeItem(accountId);
    
      // Clear the current account
      siteBionicsApplication.setCurrentAccount(undefined);

      
      // Close the dialog
      setDeletingAccountName(undefined);

      setBusy(false);

    });
    
  } 

  const handleEdit = (accountName: string, billingAddress: Address) => {
    event("edit", "confirm");

    setBusy(true);
    siteBionicsApplication.service.updateAccount(siteBionicsApplication.currentAccount!.accountId, accountName, billingAddress).then(() => {
      siteBionicsApplication.currentAccount?.updateData(accountName, billingAddress);     

      setEditing(false);
      setBusy(false);
    });    
  }

  const event = (operation: 'add' | 'edit' | 'delete', action: 'click' | 'cancel' | 'confirm') => {
    if (operation === 'add' && action !== 'confirm')
      ReactGA.event("account_" + operation + "_" + action);
    else
      ReactGA.event("account_" + operation + "_" + action, {accountId: siteBionicsApplication.currentAccount!.accountId});
  };
  
  return (
    <>

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleToolbar title="Accounts" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
        <>
          <Button variant="outlined" size="small" disabled={loading} onClick={() => {setAdding(true); event("add", "click")}}>Add</Button>            
          <Button variant="outlined" size="small" onClick={() => {setEditing(true); event("edit", "click")}} disabled={!siteBionicsApplication.currentAccount || !siteBionicsApplication.currentAccount?.hasAccountCapability("UpdateAccount")}>Edit</Button>            
          <Button variant="outlined" size="small" onClick={() => {setDeletingAccountName(siteBionicsApplication.currentAccount!.accountName); event("delete", "click")}} disabled={!siteBionicsApplication.currentAccount || !siteBionicsApplication.currentAccount?.hasAccountCapability("DeleteAccount")}>Delete</Button>
        </>
      </TitleToolbar>        

      <Box component="div" sx={{height: "10pt"}}/>

            
      {loading &&
        <Box component="div" display="flex" flexDirection="column" sx={{padding: '20px'}}> 
          <Typography>Loading...</Typography>
        </Box>
      }
      {!loading && siteBionicsApplication.accountList && siteBionicsApplication.accountList?.length === 0 && 
        <Typography>No available accounts</Typography>
      }
      {!loading && siteBionicsApplication.accountList && siteBionicsApplication.accountList?.length > 0 &&
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Account Name</TableCell>
                <TableCell align="left">Account Id</TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ overflowY: "auto" }}>
              {siteBionicsApplication.accountList?.map((a) => {
                const isSelected = a.accountId === siteBionicsApplication.currentAccount?.accountId;
                return (
                  <TableRow key={a.accountId} selected={isSelected} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} aria-selected={isSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected}
                        onClick={() => { siteBionicsApplication.setCurrentAccount(a); }}
                      />
                    </TableCell>
                    <TableCell>
                      <NavLink style={{ color: 'white' }} to={`/accounts/${a.accountId}`}>{a.accountName}</NavLink>
                    </TableCell>
                    <TableCell>{a.accountId}</TableCell>
                  </TableRow>
                )
              }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      {adding && 
        <AccountDialog
          title="Add Account"
          saveLabel="Add Account"
          nameLabel="Account Name"
          addressLabel="Billing Address:"
          
          onClose={() => {setAdding(false); event("add", "cancel")}}
          onSave={handleAdd}
        />
      }            
      {editing && 
        <AccountDialog 
          title="Edit Account" 
          saveLabel="Save Account"
          nameLabel="Account Name"
          addressLabel="Billing Address:"
          name={siteBionicsApplication.currentAccount!.accountName}
          address={siteBionicsApplication.currentAccount!.billingAddress}
          onClose={() => {setEditing(false); event("edit", "cancel")}}
          onSave={handleEdit}
        />              
      }
      {deletingAccountName &&           
        <Confirm
          title="Delete Account"
          confirmationText={`Are you sure you want to delete ${deletingAccountName}?`}
          confirmButtonLabel="Delete"
          onClose={() => {setDeletingAccountName(undefined); event("delete", "cancel")}}
          onConfirm={handleDelete}
        />
      }
    </>
  )
})

export default AccountsList;
