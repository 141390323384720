import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig"
import { Divider, Stack } from '@mui/material';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { AccountCircle, Storefront, BusinessCenter } from '@mui/icons-material';
import useWindowSize from '../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';

interface HeaderBarProps {
  modal?: boolean;
}

const HeaderBar: React.FC<HeaderBarProps> = observer(({
  modal = false
}) => {
  const siteBionicsApplication = useSiteBionicsApplication();

  const { width, height } = useWindowSize();

  const navigate = useNavigate();

  const msal = useMsal();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElSite, setAnchorElSite] = React.useState<null | HTMLElement>(null);
  const [anchorElAccount, setAnchorElAccount] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  
  const maxMenu = width / 6;
  
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [anchorElAbout, setAnchorElAbout] = React.useState<null | HTMLElement>(null);

  const sizeWH = 40;
  
  const isAuthenticated = !!(siteBionicsApplication.me);

  const handleLogin = () => {
    setAnchorElUser(null);
    msal.instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log(e);
    });
  }
  
  const handleLogout = () => {
    setAnchorElUser(null);
    navigate("/");
    siteBionicsApplication.clearMe();
    msal.instance.logoutRedirect({postLogoutRedirectUri: "/",});
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccount(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAnchorElAccount(null);
  };

  const handleOpenSiteMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSite(event.currentTarget);
  };

  const handleCloseSiteMenu = () => {
    setAnchorElSite(null);
  };
  
  const simulateError = () => {
    siteBionicsApplication.setLastError('Error refreshing list of accounts.', "error");
    setAnchorElUser(null);
  };

  return (
    <div style={{minWidth: "380px"}}>
      <AppBar position="static" style={{ backgroundColor: '#012243', height: 64, whiteSpace: "nowrap"}}>

          <Toolbar  style={{ backgroundColor: '#012243', height: 64 }}>

            <img src="/site-bionics.jpg" height={sizeWH} alt="Site Bionics"/>

            <Box component="div" sx={{ flexGrow: 1}}/>

            { !isAuthenticated && !modal && 
              <Button variant="contained" onClick={handleLogin} >
                Login
              </Button>
            }

            { isAuthenticated && !modal &&
              <Box component="div" sx={{ flexGrow: 0 }}>
                {siteBionicsApplication.currentSite &&
                  <>
                    <Button onClick={handleOpenSiteMenu} size="small">
                      <Storefront style={{ color: 'aqua', width: sizeWH, height: sizeWH, marginRight: !isSmallScreen ? "5px" : "0" }} />
                      {!isSmallScreen && 
                        <div style={{maxWidth: maxMenu, overflowX: "hidden", textOverflow: "ellipsis"}}>
                          {siteBionicsApplication.currentSite.siteName}
                        </div>
                      }
                    </Button>
                    <Menu sx={{ mt: '45px' }} id="menu-site" anchorEl={anchorElSite} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                        keepMounted transformOrigin={{vertical: 'top', horizontal: 'right',}} open={Boolean(anchorElSite)} onClose={handleCloseSiteMenu}>
                      <Stack>
                        <>                          
                          <MenuItem component={Link} to="/profile" onClick={handleCloseSiteMenu} disabled>
                            <Box component="div" display="flex" flexDirection="column" alignItems="center" sx={{padding: "10px", backgroundColor: "lightgrey", width: "100%"}}>             
                              <Typography variant="body1" color="black">{siteBionicsApplication.currentSite.siteName}</Typography>                                
                            </Box>    
                          </MenuItem>  
                      


                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites`} onClick={handleCloseSiteMenu}>Switch Site</MenuItem>
                          <Divider />
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}`} onClick={handleCloseSiteMenu}>Overview</MenuItem>
                          <Divider />
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/viewer`} onClick={handleCloseSiteMenu}>3D Site Viewer</MenuItem>
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/video`} onClick={handleCloseSiteMenu}>View Video</MenuItem>
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/tracking`} onClick={handleCloseSiteMenu}>Site Activity</MenuItem>
                          {siteBionicsApplication.currentSite?.hasAnySiteCapability(["UpdateSite", "ManageSiteUsers"]) &&
                            <Divider />
                          }
                          {siteBionicsApplication.currentSite?.hasSiteCapability("UpdateSite") &&
                            <>
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/hardware`} onClick={handleCloseSiteMenu}>Manage Hardware</MenuItem>                          
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/areas`} onClick={handleCloseSiteMenu}>Manage Site Scans</MenuItem>
                            </>
                          }
                          {siteBionicsApplication.currentSite?.hasSiteCapability("ManageSiteUsers") &&
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/users`} onClick={handleCloseSiteMenu}>Manage Access</MenuItem>
                          }
                          <Divider />
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/dashboard`} onClick={handleCloseSiteMenu}>Dashboard</MenuItem>
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/shifts`} onClick={handleCloseSiteMenu}>Shifts</MenuItem>
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/heatmap`} onClick={handleCloseSiteMenu}>Heatmap</MenuItem>
                          {siteBionicsApplication.currentSite?.hasSiteCapability("UpdateSite") &&
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/editor`} onClick={handleCloseSiteMenu}>3D Site Editor</MenuItem>
                          }
                        </>                    
                      </Stack>
                    </Menu>
                  </>
                }
                {siteBionicsApplication.currentAccount && <>
                  <Button onClick={handleOpenAccountMenu}>
                    <BusinessCenter sx={{ color: 'aqua', width: sizeWH, height: sizeWH, marginRight: !isSmallScreen ? "5px" : "0" }}/>
                    {!isSmallScreen && 
                        <div style={{maxWidth: maxMenu, overflowX: "hidden", textOverflow: "ellipsis"}}>{siteBionicsApplication.currentAccount.accountName}</div>
                    }
                  </Button>
                  <Menu sx={{ mt: '45px' }} id="menu-account" anchorEl={anchorElAccount} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                        keepMounted transformOrigin={{vertical: 'top', horizontal: 'right',}} open={Boolean(anchorElAccount)} onClose={handleCloseAccountMenu}>
                      <Stack>                  
                        <>
                            <MenuItem component={Link} to="/profile" onClick={handleCloseAccountMenu} disabled>
                              <Box component="div" display="flex" flexDirection="column" alignItems="center" sx={{padding: "10px", backgroundColor: "lightgrey", width:"100%"}}>             
                                <Typography variant="body1" color="black">{siteBionicsApplication.currentAccount.accountName}</Typography>                                
                              </Box>    
                            </MenuItem>  

                            <MenuItem component={Link} to={`/accounts`} onClick={handleCloseAccountMenu}>Switch Account</MenuItem>
                            <Divider />
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}`} onClick={handleCloseAccountMenu}>Overview</MenuItem>
                            <Divider />
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites`} onClick={handleCloseAccountMenu}>Sites</MenuItem>
                            <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/users`} onClick={handleCloseAccountMenu}>Team Members</MenuItem>
                        </>                          
                      </Stack>
                  </Menu>
                </>}
                <Button onClick={handleOpenUserMenu}>
                  <AccountCircle sx={{ color: 'aqua', width: sizeWH, height: sizeWH}}/>
                </Button>                
                <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                    keepMounted transformOrigin={{vertical: 'top', horizontal: 'right',}} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                    <Stack> 
                      <MenuItem component={Link} to="/profile" onClick={handleCloseUserMenu} disabled>
                        <Box component="div" display="flex" flexDirection="column" alignItems="center" sx={{padding: "10px", backgroundColor: "lightgrey", width: "100%"}}>             
                          <Typography variant="body1" color="black">{siteBionicsApplication.me!.firstName + " " + siteBionicsApplication.me!.lastName}</Typography>
                          <Typography variant="body1" color="black">{siteBionicsApplication.me!.email} </Typography>
                        </Box>    
                      </MenuItem>  
                      <MenuItem component={Link} to="/profile" onClick={handleCloseUserMenu}>Profile</MenuItem>                          
                      <Divider />
                      <MenuItem component={Link} to="/accounts" onClick={handleCloseUserMenu}>Accounts</MenuItem>
                      <Divider />
                      <MenuItem key="Logout" onClick={handleLogout}>Logout</MenuItem>
                      {siteBionicsApplication.isSystemAdministrator &&
                      (<>
                        <Divider />
                        <MenuItem component={Link} to="/management" onClick={handleCloseUserMenu}>Management</MenuItem>
                        <MenuItem component={Link} to="/management/users" onClick={handleCloseUserMenu} sx={{ paddingLeft: '20pt' }}>All Users</MenuItem>
                        <MenuItem component={Link} to={`/management/provisionedhubs`} onClick={handleCloseUserMenu} sx={{ paddingLeft: '20pt' }}>Provisioned Hubs</MenuItem>
                        {siteBionicsApplication.currentAccount && siteBionicsApplication.currentSite &&
                          (
                          <MenuItem component={Link} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${siteBionicsApplication.currentSite!.siteId}/managevideo`} sx={{ paddingLeft: "20pt"}}>Video</MenuItem>
                        )}
                        <MenuItem component={Link} to="/debug/developer" onClick={handleCloseUserMenu}>Developer Info</MenuItem>
                        <MenuItem onClick={simulateError}>Simulate Error</MenuItem>
                      </>)}
                    </Stack>
                </Menu>
              </Box>              
            }             
                          
          </Toolbar>
      </AppBar>
    </div>
  );
})

export default HeaderBar;

