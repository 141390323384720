import { action, makeObservable, observable } from "mobx";
import Scan from "./Scan";
import ScanArea from "./ScanArea";
import { ModelType } from "./ModelType";
import Site from "./Site";
import ScanAreaLayout from "./layout/ScanAreaLayout";
import Hub from "./Hub";
import Camera from "./Camera";
import TrackedObject from "./TrackedObject";
import Tracklet from "./Tracklet";
import SiteIntegration from "./SiteIntegration";
import { Vector3 } from "@babylonjs/core";
import dayjs, { Dayjs } from "dayjs";

export default class SiteNavigator {

    @observable site: Site;
    @observable currentScanArea?: ScanArea;
    @observable currentScanAreaLayout?: ScanAreaLayout;
    @observable currentScan?: Scan;
    @observable currentHub?: Hub;
    @observable currentCamera?: Camera;
    @observable currentTrackedObject?: TrackedObject;
    @observable currentTrackedObjects?: TrackedObject[];
    @observable currentTracklet?: Tracklet;
    @observable currentTracklets?: Tracklet[];
    @observable currentIntegration?: SiteIntegration;
    @observable currentModelType: ModelType = ModelType.Lidar;
    @observable currentPosition: Vector3 = Vector3.Zero();
    @observable currentPositionUndistorted: Vector3 = Vector3.Zero();
    @observable startTime: Dayjs = dayjs().startOf('day').add(12, 'hour');
    @observable endTime: Dayjs = dayjs().startOf('day').add(12, 'hour').add(1, 'minute');
    @observable currentTime: Dayjs = dayjs().endOf('day');
    @observable videoStartTime: Dayjs = dayjs().startOf('day').add(12, 'hour');
    @observable videoEndTime: Dayjs = dayjs().startOf('day').add(12, 'hour').add(1, 'minute');
    @observable videoTime: Dayjs = dayjs().endOf('day');
    @observable videoTimeOffset: number = 0;

    
    constructor(site: Site) {
        this.site = site;
        makeObservable(this);
    }

    @action
    setCurrentPosition(position: Vector3) {
        this.currentPosition = position;
    }
    
    @action
    setCurrentPositionUndistorted(position: Vector3) {
        this.currentPositionUndistorted = position;
    }

    @action
    setCurrentScanArea(scanArea?: ScanArea) {
        this.currentScanArea = scanArea;
        this.currentScan = undefined;
        this.currentScanArea?.loadScansAsync().then((scans) => {
            if (scans && scans.length > 0) {
                this.setCurrentScan(scans[scans.length-1]);
            }
        });
    }

    @action
    setCurrentScanAreaById(scanAreaId: string) {
        this.setCurrentScanArea(this.site.scanAreas?.find((sa) => sa.scanAreaId === scanAreaId)!);
    }
    
    @action
    setCurrentScan(currentScan?: Scan) {
        this.currentScan = currentScan;
    }

    @action
    setCurrentScanByScanId(scanId: string) {
        this.setCurrentScan(this.currentScanArea?.scans?.find((s) => s.scanId === scanId));
    }
        
    @action
    setCurrentModelType(currentModeType: ModelType) {
        this.currentModelType = currentModeType;
    }

    @action
    setCurrentScanAreaLayout(currentScanAreaLayout?: ScanAreaLayout) {
        this.currentScanAreaLayout = currentScanAreaLayout;
    }
    
    @action
    setCurrentHub(currentHub?: Hub) {
        this.currentHub = currentHub;
    }

    @action
    setCurrentCamera(currentCamera?: Camera) {
        this.currentCamera = currentCamera;
    }

    @action
    setCurrentCameraById(cameraId: string) {
        this.setCurrentCamera(this.site.cameras?.find((c) => c.cameraId === cameraId));
    }

    @action
    setCurrentTrackedObject(currentTrackedObject?: TrackedObject) {
        this.currentTrackedObject = currentTrackedObject;
    }

    @action
    setCurrentTrackedObjects(currentTrackedObjects?: TrackedObject[]) {
        this.currentTrackedObjects = currentTrackedObjects;
    }

    @action
    setCurrentTracklet(currentTracklet?: Tracklet) {
        this.currentTracklet = currentTracklet;
    }

    @action
    setCurrentTracklets(currentTracklets?: Tracklet[]) {
        this.currentTracklets = currentTracklets;
    }

    @action
    setCurrentIntegration(currentIntegration?: SiteIntegration) {
        this.currentIntegration = currentIntegration;
    }

    @action
    setStartTime(startTime: Dayjs) {
        this.startTime = startTime;
    }

    @action
    setEndTime(endTime: Dayjs) {
        this.endTime = endTime;
    }

    @action
    setVideoStartTime(videoStartTime: Dayjs) {
        this.videoStartTime = videoStartTime;
    }

    @action
    setVideoEndTime(videoEndTime: Dayjs) {
        this.videoEndTime = videoEndTime;
    }

    @action
    setCurrentTime(currentTime: Dayjs) {
        this.currentTime = currentTime;
    }
    
    @action
    setVideoTime(videoTime: Dayjs) {
        this.videoTime = videoTime;
    }

    @action
    setVideoTimeOffset(videoTimeOffset: number) {
        this.videoTimeOffset = videoTimeOffset;
    }

}
