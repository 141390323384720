import React, { ReactNode, useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from "dayjs";
import { after, before } from 'node:test';
import { EngineeringRounded } from '@mui/icons-material';

// Define the possible types for granularity
type Granularity = 'small' | 'large';

// Define the shape of the props for the component
interface TimeSpanControlProps {
  onGo: (from: Dayjs, to: Dayjs) => void;
  goLabel?: string;
  allowCustom?: boolean;
  initialFromDate: Dayjs;
  initialToDate: Dayjs;
  initialDuration: string;  
  granularity: Granularity;
  children?: ReactNode;
}

const TimeSpanControl: React.FC<TimeSpanControlProps> = ({
  onGo,
  goLabel,
  allowCustom = false,
  initialFromDate,
  initialToDate,  
  initialDuration,  
  granularity,
  children
}) => {

  // Define the options for the duration dropdown
  const durationOptionsSmall = ['1 Minute', '2 Minutes', '3 Minutes', '4 Minutes', '5 Minutes'];
  const durationOptionsLarge = ['Day', 'Week', 'Month', 'Year'];
  const durationValues = {'1 Minute': 60, '2 Minutes': 120, '3 Minutes': 180, '4 Minutes': 240, '5 Minutes': 300, 'Day': 86400, 'Week': 604800, 'Month': 259200, 'Year': 31536000};

  // State hooks to manage user input
  
  const [startDate, setStartDate] = useState<Dayjs>(initialFromDate);  
  const [endDate, setEndDate] = useState<Dayjs>((allowCustom && initialDuration === "Custom") ? initialToDate : initialFromDate.add(durationValues[initialDuration as keyof typeof durationValues], 'seconds'));
  const [duration, setDuration] = useState<string>(initialDuration);
  
  // Handle the "Go" button click event
  const handleGoClick = () => {
    onGo(startDate, endDate);
  };

  
  const changeStartDate = (date: Dayjs | null) => {
    if (date === null) return;

    setStartDate(date);
    
    if (duration !== "Custom") {
        setEndDate(date.add(durationValues[duration as keyof typeof durationValues], 'seconds'));
    } 
  }

  const changeEndDate = (date: Dayjs | null) => {
    if (date === null) return;

    setEndDate(date);
    
    if (duration !== "Custom") {
        setStartDate(date.subtract(durationValues[duration as keyof typeof durationValues], 'seconds'));
    }
  }

  // Render DateTimePicker component
  const renderDateTimePicker = (
    label: string,
    value: Dayjs | null,
    onChange: (date: Dayjs | null) => void
  ) => (
    
    <DateTimePicker
      label={label}
      value={value}
      onChange={onChange}
      //inputFormat="yyyy-MM-dd HH:mm" // Specify input format
      ampm={false}
    />
  );

  const changeDuration = (value: string) => {
    if (value !== "Custom") {
        const seconds = durationValues[value as keyof typeof durationValues];
        setEndDate(startDate.add(durationValues[value as keyof typeof durationValues], 'seconds'));
    }
    
    setDuration(value);    
  }

  // Render Duration dropdown based on the granularity
  const renderDurationDropdown = (label: string) => {
    const options = granularity === 'small' ? durationOptionsSmall : durationOptionsLarge;
    return (
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          value={duration}
          onChange={(e) => changeDuration(e.target.value)}
          label={label}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
          {allowCustom &&
            <MenuItem key="Custom" value="Custom">Custom</MenuItem>
          }
        </Select>
      </FormControl>
    );
  };  
  
  return (
    <>
      <Box component="div" display="flex"  flexDirection="row" gap={2}>
        
        {renderDateTimePicker('From Date', startDate, changeStartDate)}
        {renderDurationDropdown('Duration')}
        {renderDateTimePicker('To Date', endDate, changeEndDate)}   

        {children ? children : <></>}

        {/* Go Button */}
        <Button          
          onClick={handleGoClick}
          size="small"
        >
          {goLabel ?? "Refresh"}
        </Button>
      </Box>
    </>
  );
};

export default TimeSpanControl;
