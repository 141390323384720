import React, {useEffect, ReactElement}  from 'react';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';

interface WithTitleProps {
  title?: string;
  context?: 'account' | 'site' | undefined;
  children?: ReactElement;
}


const WithTitle: React.FC<WithTitleProps> = ({
    title,
    context,
    children
  }) => {
  const siteBionicsApplication = useSiteBionicsApplication();

  useEffect(() => {
    let components = [];
    if (title)
      components.push(title);
    switch (context) {
      case 'account':
        components.push(siteBionicsApplication.currentAccount!.accountName);
        break;
      case 'site':
        components.push(siteBionicsApplication.currentSite!.siteName);
        break;
    }
    components.push('Site Bionics');
    document.title = components.join(' | ');
  }, [title]);

    return (
        <>
            {children}
        </>
    );
};

export default WithTitle;
