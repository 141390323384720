
export default class Shift {

    firstName: string;
    lastName: string;
    employeeId: string;
    startTime: Date;
    endTime: Date;

    constructor(firstName: string, lastName: string, employeeId: string, startTime: Date, endTime: Date) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.employeeId = employeeId;
        this.startTime = startTime;
        this.endTime = endTime;
    }
}

// We need this because javascript will either parse a date in local time, or in UMT. When we import
// dates from a CSV and send to the server, the timezone is an attribute of the site. We need time "without a zone".
// We store it a string to prevent timezone games
export class ShiftUpdate {
    firstName: string;
    lastName: string;
    employeeId: string;
    startTime: string;
    endTime: string;

    constructor(firstName: string, lastName: string, employeeId: string, startTime: string, endTime: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.employeeId = employeeId;
        this.startTime = startTime;
        this.endTime = endTime;
    }

}