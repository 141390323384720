import { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react"

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import 'm-react-splitters/lib/splitters.css';
import { encodeColor } from "../util/Colors";
import Tracklet, { TrackletType } from "../models/Tracklet";
import { Color3 } from "@babylonjs/core";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SiteNavigator from "../models/SiteNavigator";

interface TrackletsTimelineProps {
    selectTracklet: (tracklet: Tracklet) => void;
    siteNavigator: SiteNavigator;
}

const TrackedObjectsTimeline: FC<TrackletsTimelineProps> = observer(({
    selectTracklet, siteNavigator }) => {

    const siteBionicsApplcation = useSiteBionicsApplication();
    const rowRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});
    const [tracklets, setTracklets] = useState<Tracklet[]>([]);

    const minTime = siteNavigator.startTime?.toDate().getTime() ?? 0;
    const maxTime = siteNavigator.endTime?.toDate().getTime() ?? 0;
    const timeSpan = maxTime - minTime;

    useEffect(() => {
        let filteredTracklets = siteNavigator.currentTrackedObject?.tracklets
                .filter(tracklet => tracklet.trackletType === TrackletType.CameraDetection
                    && tracklet.startTime.getTime() <= maxTime && tracklet.endTime.getTime() >= minTime)
                .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
        setTracklets(filteredTracklets ?? []);
    }, [siteNavigator.currentTrackedObject]);

    // Scroll to the selected row when the current Tracklet changes
    useEffect(() => {
        if (siteNavigator.currentTracklet  && rowRefs.current[siteNavigator.currentTracklet.trackletId]) {
        rowRefs.current[siteNavigator.currentTracklet.trackletId]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        }
    }, [siteNavigator.currentTracklet]);
    
    const handleClick = (event: React.MouseEvent<unknown>, trackletId: string) => {
        const tracklet = tracklets.find(tracklet => tracklet.trackletId === trackletId);
        if (tracklet) {
            selectTracklet(tracklet);
        }
    };
    
    const handleDoubleClick = (event: React.MouseEvent<unknown>, trackletId: string) => {
        const tracklet = tracklets.find(tracklet => tracklet.trackletId === trackletId);
        if (tracklet) {
            selectTracklet(tracklet);
        }
    };

    const getBarStyle = (tracklet: Tracklet) => {
        let startTime = tracklet.startTime.getTime();
        let endTime = tracklet.endTime.getTime();
        startTime = Math.max(startTime, minTime);
        endTime = Math.min(endTime, maxTime);
        const leftOffset = ((startTime - minTime) / timeSpan) * 100;
        const width = ((endTime - startTime) / timeSpan) * 100;
        let color = tracklet.camera?.color ?? Color3.White();
        return {
            marginLeft: `${leftOffset}%`,
            width: `${width}%`,
            height: '100%',
            backgroundColor: encodeColor(color),
            borderRadius: '3px'
        };
    };

    return (
        <Box component="div">
            {tracklets && tracklets.length > 0 &&
                <TableContainer sx={{ width: "100%", height: "100%", paddingLeft: "10pt", paddingRight: "10pt" }}>
                    <Table size="small" stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell width={30}>#</TableCell>
                                <TableCell>Time Span</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowX: "auto" }}>
                            {tracklets.map((tracklet, index) => (
                                <TableRow
                                    key={tracklet.trackletId} 
                                    onClick={(event) => handleClick(event, tracklet.trackletId)}
                                    onDoubleClick={(event) => handleDoubleClick(event, tracklet.trackletId)}
                                    selected={tracklet.trackletId === siteNavigator.currentTracklet?.trackletId}
                                    ref={(el) => (rowRefs.current[tracklet.trackletId] = el)}>
                                    <TableCell>{index}</TableCell>
                                    <TableCell>
                                        <div style={{ position: 'relative', width: '100%', height: '20px' }}>
                                            <div style={getBarStyle(tracklet)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {!tracklets &&
                <p>No tracklets. Select a time range and hit 'refresh' then select a tracked object.</p>
            }
        </Box>
    )
})

export default TrackedObjectsTimeline;