import { FC, useEffect, useRef, useState } from "react";
import { MantineProvider } from "@mantine/core";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "@mui/material";


interface VideoPlayerProps {
  posterSrc: string;
  hlsSrc: string;
  options?: Player["options"];
  startTime?: Dayjs;
  playFromOffset?: number;
  fluid?: boolean;
  onTimeUpdate?: (currentTime: Dayjs) => void;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
  hlsSrc,
  posterSrc,
  options,
  startTime,
  playFromOffset,
  fluid,
  onTimeUpdate,
  ...props
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);
  const updateInterval = useRef<NodeJS.Timeout | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const playerOptions = {
        controls: true,
        fluid: fluid,
        fill: true,
        responsive: true,
        sources: [{ src: hlsSrc, type: "application/x-mpegURL" }],
        poster: posterSrc,
        liveui: true,
        enableSmoothSeeking: true,
        playbackRates: [0.5, 1, 2, 5, 10],
        ...options,
      };

      playerRef.current = videojs(videoElement, playerOptions, () => {
        playerRef.current?.setAttribute("data-testid", "video-player");
        playerRef.current?.currentTime(playFromOffset ? playFromOffset : 0);
        playerRef.current?.play();
      });

      // Listening to 'seeked' event when seeking ends and send a time update
      playerRef.current?.on('seeked', () => {
        const newCurrentTime = playerRef.current?.currentTime() || 0;
        if (currentTime !== newCurrentTime && onTimeUpdate && startTime) {
          onTimeUpdate(startTime.add(newCurrentTime, 'second'));
        }
      });
  
    updateInterval.current = setInterval(() => {
        const newCurrentTime = playerRef.current?.currentTime() || 0;
        if (currentTime !== newCurrentTime && onTimeUpdate && startTime 
            && playerRef.current?.paused() === false
          ) {
          onTimeUpdate(startTime.add(newCurrentTime, 'second'));
        }
        setCurrentTime(newCurrentTime);
      }, 100);  // Check every 1/10th second
    } 
    
    // if (playerRef.current) {
    //   playerRef.current.autoplay(true);
    //   playerRef.current.src({ src: hlsSrc, type: "application/x-mpegURL"});
    //   //playerRef.current.play()
    // }

    return () => {
      if (playerRef.current) {
        if (updateInterval.current) clearInterval(updateInterval.current);
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };

  }, [hlsSrc, options, posterSrc]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <MantineProvider>
      <Box component="div" sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
        <Box component="div" ref={videoRef} sx={{objectFit: 'contain'}} width="100%" height="100%"/>
      </Box>
    </MantineProvider>
  );
};
