
import { action, makeObservable, observable } from "mobx";
import { ScanState } from "./ScanState";
import DataContext from "./DataContext";
import ScanArea from "./ScanArea";
import ScanAreaLayout from "./layout/ScanAreaLayout";
import { ModelType } from "./ModelType";

export default class Scan {

    private dataContext: DataContext;

    @observable area: ScanArea;
    @observable scanId: string;
    @observable scanVersion: number;
    @observable scanTime: Date;
    @observable scanState: ScanState;
    @observable scanAreaLayout?: ScanAreaLayout;
    @observable availableModelTypes? : ModelType[]

    constructor(dataContext: DataContext, area: ScanArea, scanId: string, scanVersion: number, scanTime: Date, scanState: ScanState) {
        this.dataContext = dataContext;
        this.area = area;
        this.scanId = scanId;
        this.scanVersion = scanVersion;
        this.scanTime = scanTime;
        this.scanState = scanState;
        makeObservable(this);
    }

    @action
    setScanAreaLayout(scanAreaLayout?: ScanAreaLayout) {
        this.scanAreaLayout= scanAreaLayout;
    }

    @action
    async loadScanAreaLayoutAsync(refresh: boolean = false): Promise<ScanAreaLayout | undefined> {
        if (this.scanAreaLayout === undefined || refresh) {
            let scanAreaLayout = await this.dataContext.service.fetchScanAreaLayoutAsync(this);
            this.setScanAreaLayout(scanAreaLayout);
        }
        return this.scanAreaLayout;
    }

    @action
    setAvailableModelTypes(availableModelTypes?: ModelType[]) {
        this.availableModelTypes = availableModelTypes;
    }

    @action
    async loadAvailableModels(refresh: boolean = false): Promise<ModelType[] | undefined> {
        if (this.availableModelTypes === undefined || refresh) {
            let availableModelTypes = await this.dataContext.service.fetchAvailbleModelTypes(this);
            this.setAvailableModelTypes(availableModelTypes);
        }
        return this.availableModelTypes;
    }

}
