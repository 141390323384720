import { int } from "@babylonjs/core";

export default class DwellTime {

    startInterval: int;
    endInterval: int;
    count: int;

    constructor(startInterval: int, endInterval: int, count: int) {
        this.startInterval = startInterval;
        this.endInterval = endInterval;
        this.count = count;
    }
}
