import { FunctionComponent, useEffect, useState } from "react";
import { Alert, Box, CircularProgress } from '@mui/material';
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from "dayjs";
import {convertTimezoneOnly} from "../util/Time";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import Shift from "../models/Shift";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useSiteNavigator } from "../pages/SitePage";
import DwellTime from "../models/DwellTime";

const bucketSize = 20;
const minFilter = 21;
const maxFilter = 300;

function cloneAndFilterDwellTimes(dwellTimes: DwellTime[], min: number, max: number): DwellTime[] {
  // Initialize the result array
  let result: DwellTime[] = [];
  
  let startBucketCount = 0;
  let endBucketCount = 0;
  
  // Iterate over the dwellTimes array
  for (let dwellTime of dwellTimes) {
      if (dwellTime.endInterval < min) {
          // Combine dwellTimes that fall before the min into the 0-min bucket
          startBucketCount += dwellTime.count;
      } else if (dwellTime.startInterval > max) {
          // Combine dwellTimes that fall after the max into the max-? bucket
          endBucketCount += dwellTime.count;
      } else {
          // For dwellTimes within the min-max range, keep them as they are
          result.push(new DwellTime(dwellTime.startInterval, dwellTime.endInterval, dwellTime.count));
      }
  }
  
  // Add the 0-min bucket 
  result.unshift(new DwellTime(0, min, startBucketCount));

  // Add the max bucket if there 
  
  // Add the max-? bucket only if there were intervals above max
  result.push(new DwellTime(max, Infinity, endBucketCount));

  return result;
}

const EngagementChart: React.FC<{ startDate: Dayjs, endDate: Dayjs, region: string}> = observer(({startDate, endDate, region}) => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const [dwellTimes, setDwellTimes] = useState<DwellTime[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [series, setSeries] = useState<any[]>([]);
  const [options, setOptions] = useState<any>(null);
  const siteStartDate = convertTimezoneOnly(startDate, siteNavigator.site.timeZone);
  const siteEndDate = convertTimezoneOnly(endDate, siteNavigator.site.timeZone);

  useEffect(() => {
    if (region === "" ) {
      setDwellTimes(null);
      return;
    }

    setIsLoading(true);

    siteBionicsApplcation.service.fetchDwellTimesAsync(siteNavigator.site, siteStartDate.utc(), siteEndDate.utc(), region, bucketSize).then((dwellTimes) => {
     
      setDwellTimes(dwellTimes);

      setIsLoading(false);
    });       
  }, [startDate, endDate, region]);

  function convertSecondsToHMS(seconds:number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  useEffect(() => {
    if (dwellTimes === null) return;

    const renderedDwellTimes = cloneAndFilterDwellTimes(dwellTimes, minFilter, maxFilter);    

    const options = {
      chart: {
        type: 'bar',
      },
      theme: { mode: 'dark' },
      xaxis: {
        categories: renderedDwellTimes.map(
          //(interval) => `${interval.startInterval}-${interval.endInterval}`
          (interval) => `${convertSecondsToHMS(interval.startInterval)}+`
        ),
        title: {
          text: "Time spent in region",
        },
      },
      yaxis: {
        title: {
          text: "Number of users",
        },
      },
      title: {
        text: "Dwell time",
        align: "center",
      },
    };
    setOptions(options);
  
    const series = [
      {
        name: "Count",
        data: renderedDwellTimes.map((interval) => interval.count),
      },
    ];
    setSeries(series);
  
  }, [dwellTimes])

  
  return (
    <Box component="div" sx={{ marginLeft: '10px', marginRight: '10px' }}>
      {!isLoading && dwellTimes && dwellTimes.length > 0 && series && options &&
        <Chart options={options} series={series} type="bar" height={400} />
      }
      {!isLoading && (!dwellTimes || dwellTimes.length === 0) && 
        <Alert severity="warning">No data for this date.</Alert>
      }
      {isLoading &&
        <CircularProgress/>
      }
    </Box>
  );
});

export default EngagementChart;
