import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"

import { Backdrop, CircularProgress, Box, Button, Checkbox, Link, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { NavLink } from "react-router-dom";
import { useAccount } from "./AccountPage";
import SiteDialog from "../dialogs/SiteDialog";
import Confirm from "../components/Confirm";
import Address from "../models/Address";
import Site from "../models/Site";
import {siteCache} from "../models/Cache";
import TitleToolbar from "../components/TitleToolbar";
import ReactGA from "react-ga4";
import CreateShadowSiteDialog from "../dialogs/CreateShadowSiteDialog";
import { on } from "events";
import Account from "../models/Account";

const SitesList : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const account = useAccount();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [busy, setBusy] = useState(false);

  const [editing, setEditing] = useState(false);
  const [deletingSiteName, setDeletingSiteName] = useState<string | undefined>(undefined);
  const [adding, setAdding] = useState(false);
  const [creatingShadow, setCreatingShadow] = useState(false);

  const [loading, setLoading] = useState(!account?.sites);

  useEffect(() => {
    if (account.sites === undefined) {
      setLoading(true);
      account.loadSitesAsync().then(() => {    
        setLoading(false);
      });
    } 

    if (!siteBionicsApplication.currentSite && account.sites && account.sites.length > 0) {
      siteBionicsApplication.setCurrentSite(account.sites[0]);
    }

  }, [account, account.sites]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    event("delete", "confirm");

    setBusy(true);

    const accountId = account.accountId;
    const siteId = siteBionicsApplication.currentSite!.siteId;

    siteBionicsApplication.service.deleteSiteAsync(accountId, siteId).then(() => {

      // Remove from the sites in the account
      let index = account.sites?.findIndex(obj => siteId === obj.siteId);

      // If the site is in the site list, remove it
      if (index !== -1) {
        account.sites!.splice(index!, 1);
      }

      // Remove it from the MRU list
      if (siteBionicsApplication.mruData) {
        siteBionicsApplication.mruData.removeSite(accountId, siteId);
      }
      
      // Remove from the cache
      siteCache.removeItem(siteBionicsApplication.currentSite!.siteId);

      // Clear the current site
      siteBionicsApplication.setCurrentSite(undefined);

      setDeletingSiteName(undefined);
      setBusy(false);   
    });
  }

  const handleAdd = (name: string, timeZone: string, openHours: number[], address: Address) => {

    setBusy(true);
    siteBionicsApplication.service.createSiteAsync(account, name, timeZone!, openHours, address).then((newSite: Site) => {
      
      siteBionicsApplication.currentAccount!.sites?.push(newSite);
      
      // Add to the cache
      siteCache.addItem(newSite);

      siteBionicsApplication.setCurrentSite(newSite);

      event("add", "confirm");

      setAdding(false);
      setBusy(false);
    });
  }

  const handleEdit = (name: string, timeZone: string, openHours: number[], address: Address) => {
    event("edit", "confirm");

    setBusy(true);
    siteBionicsApplication.service.updateSiteAsync(account.accountId, siteBionicsApplication.currentSite!.siteId, name, timeZone, openHours, address).then(() => {
      siteBionicsApplication.currentSite!.updateData(name, timeZone, openHours, address);
      setEditing(false);
      setBusy(false);
    });
  }

  const handleCreateShadow = (destAccount: Account, destSiteName: string) => {

    setBusy(true);
    siteBionicsApplication.service.createShadowSiteAsync(siteBionicsApplication.currentSite!, destAccount, destSiteName).then((newSite: Site) => {

      // Add to the cache
      siteCache.addItem(newSite);

      if (siteBionicsApplication.currentAccount?.accountId === destAccount.accountId) {
        siteBionicsApplication.currentAccount!.sites?.push(newSite);
        siteBionicsApplication.setCurrentSite(newSite);
      }

      event("createShadow", "confirm");

      setCreatingShadow(false);
      setBusy(false);
    });
  }

  const event = (operation: 'add' | 'edit' | 'delete' | 'createShadow', action: 'click' | 'cancel' | 'confirm') => {
    if (operation === 'add' && action !== 'confirm')
      ReactGA.event("site_" + operation + "_" + action)
    else
      ReactGA.event("site_" + operation + "_" + action, {
        accountId: siteBionicsApplication.currentAccount!.accountId,
        siteId: siteBionicsApplication.currentSite!.siteId
      });
  };

  const breadcrumbs = [
    {name: "Accounts", link: "/accounts"},
    {name: `${siteBionicsApplication.currentAccount?.accountName}`, link: `/accounts/${siteBionicsApplication.currentAccount?.accountId}`}, 
    {name: "Sites", link: ""} ];
  
  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleToolbar title="Sites" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
          <>
            <Button variant="outlined" size="small" disabled={loading || !account!.hasAccountCapability("ManageSites")} onClick={() => {setAdding(true); event("add", "click")}}>Add...</Button>                
            <Button variant="outlined" size="small" disabled={!(siteBionicsApplication.currentSite && (account!.hasAccountCapability("ManageSites") || (siteBionicsApplication.currentSite?.hasSiteCapability("UpdateSite"))))} onClick={() => {setEditing(true); event("edit", "click")}}>Edit...</Button>
            <Button variant="outlined" size="small" disabled={!(siteBionicsApplication.currentSite && account!.hasAccountCapability("ManageSites"))} onClick={() => {setDeletingSiteName(siteBionicsApplication.currentSite!.siteName); event("delete", "click")}}>Delete...</Button>
            <Button variant="outlined" size="small" onClick={handleMenuClick} disabled={!(siteBionicsApplication.currentSite && siteBionicsApplication.currentAccount?.hasBaseSiteCapability("ViewSite"))}>View...</Button>
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
              <MenuItem component={Link} href="/site" onClick={handleMenuClose}>Detail</MenuItem>
              <MenuItem component={Link} href="/dashboards" onClick={handleMenuClose}>Dashboards</MenuItem>
              <MenuItem component={Link} href="/viewer" onClick={handleMenuClose}>3D Viewer</MenuItem>
              <MenuItem component={Link} href="/video" onClick={handleMenuClose}>Video</MenuItem>
            </Menu>
            {siteBionicsApplication.isSystemAdministrator &&
              <Button variant="outlined" size="small" disabled={loading || !(siteBionicsApplication.currentSite)} onClick={() => {setCreatingShadow(true); event("createShadow", "click")}}>Create Shadow...</Button>
            }
          </>
        </TitleToolbar> 

      <Box component="div" sx={{height: 20}}/>      
                             
      {loading && <Typography>Loading...</Typography>}

      {siteBionicsApplication.currentAccount?.sites && siteBionicsApplication.currentAccount.sites.length === 0 &&
        <Typography>No available sites</Typography>
      }

      {siteBionicsApplication.currentAccount?.sites && siteBionicsApplication.currentAccount.sites.length > 0 &&

        <TableContainer component={Paper} sx={{}} >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell>Site Name</TableCell>
                <TableCell align="left">Site Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {siteBionicsApplication.currentAccount?.sites?.map((s) => {
                const isSelected = s.siteId === siteBionicsApplication.currentSite?.siteId;
                return (
                <TableRow key={s.siteId} selected={isSelected} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} aria-selected={isSelected}>
                  <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isSelected}
                          onClick={ () =>{siteBionicsApplication.setCurrentSiteByIdAsync(s.account.accountId, s.siteId)}}
                        />
                      </TableCell>
                  <TableCell><NavLink style={{ color: 'white' }} to={`/accounts/${siteBionicsApplication.currentAccount!.accountId}/sites/${s.siteId}`}>{s.siteName}</NavLink></TableCell>
                  <TableCell align="left">{s.siteId}</TableCell>
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
      }
      
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>
      {adding &&
        <SiteDialog
          title="Add Site"
          saveLabel="Create Site"
          timeZone="America/Los_Angeles"
          openHours={Array(14).fill(0)}
          onClose={() => {setAdding(false); event("add", "cancel")}}
          onSave={handleAdd}
        />
      }
      {editing &&
        <SiteDialog
          title="Edit Site"
          saveLabel="Save Site"
          name={siteBionicsApplication.currentSite!.siteName}
          address={siteBionicsApplication.currentSite!.address}
          timeZone={siteBionicsApplication.currentSite!.timeZone!}
          openHours={siteBionicsApplication.currentSite!.openHours}
          onClose={() => {setEditing(false); event("edit", "cancel")}}
          onSave={handleEdit}
        />
      }
      {deletingSiteName &&
        <Confirm
          title="Delete Site"
          confirmationText={`Are you sure you want to delete ${deletingSiteName}?`}
          confirmButtonLabel="Delete"
          onClose={() => {setDeletingSiteName(undefined); event("delete", "cancel")}}
          onConfirm={handleDelete}
        />
      }
      {creatingShadow && siteBionicsApplication.currentSite &&
        <CreateShadowSiteDialog
          site={siteBionicsApplication.currentSite}
          onCancel={() => {setCreatingShadow(false); event("createShadow", "cancel")}}
          onCreate={handleCreateShadow}
        />
      }
    </>
  )
})

export default SitesList;