import React, { useState } from 'react';
import  {SxProps} from '@mui/system';

import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';

interface DurationPickerProps {
  onChange: (value: number) => void;
  sx?: SxProps;
}

const DurationPicker: React.FC<DurationPickerProps> = ({ sx, onChange }) => {
  const [selectedDuration, setSelectedDuration] = useState<string>("1");

  const durationList = [ {desc: "1 minute", duration: "1"},
                        {desc: "2 minute", duration: "2"},
                        {desc: "3 minute", duration: "3"},
                        {desc: "4 minute", duration: "4"},
                        {desc: "5 minute", duration: "5"},
                        {desc: "10 minute", duration: "10"},
                        {desc: "15 minute", duration: "15"},
                        {desc: "1 hour", duration: "60"}
                        ];
  
    const handleDurationChange = (event: SelectChangeEvent) => {
      setSelectedDuration(event.target.value);
      let newValue = parseInt(event.target.value);
      onChange(newValue);
  };

  return (
    <FormControl variant="outlined" sx={sx}>
      <InputLabel id="duration-select-label">Duration</InputLabel>
      <Select
        labelId="duration-select-label"
        value={selectedDuration}
        onChange={handleDurationChange}
        label="Duration"
      >
        {durationList.map((entry, index) => (
          <MenuItem key={index} value={entry.duration}>
            {entry.desc}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DurationPicker;
