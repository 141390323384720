import { Paper, Checkbox, Grid, FormGroup, FormLabel, Tooltip, MenuItem, FormControl, FormControlLabel, InputLabel, Select, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import  Camera, {CameraType} from "../models/Camera";
import { observer } from "mobx-react-lite";
import Site from "../models/Site";
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

export interface CameraDialogProps {
  camera: Camera;  
  site: Site;
  title: string;
  saveButtonLabel: string;
  onCancel: () => void;
  onSave: (camera: Camera) => void;
}

function CameraDialog(props: CameraDialogProps) {
  
  const { camera, onCancel, onSave, title, saveButtonLabel, site } = props;

  const {control, watch, register, handleSubmit, setValue, formState: {errors, isValid, touchedFields}} = useForm<Camera>({
    defaultValues: {
      address: camera.address,
      cameraName: camera.cameraName,
      cameraType: camera.cameraType,
      hubId: camera.hubId,
      manufacturer: camera.manufacturer,
      model: camera.model,
      onvifChannel: camera.onvifChannel,
      onvifSubStream: camera.onvifSubStream,
      autoRtspUrl: camera.autoRtspUrl,
      rtspUrl: camera.rtspUrl,
      scanAreaId: camera.scanAreaId,
      serialNumber: camera.serialNumber
    },
    mode: "onBlur"
  });

  const handleCancelClick = () => {

    onCancel();
  };
 
  const handleSaveClick = (data: Camera) => {

    camera.address = data.address;
    camera.cameraName = data.cameraName;
    camera.cameraType = data.cameraType;
    camera.hubId = data.hubId;
    camera.manufacturer = data.manufacturer;
    camera.model = data.model;
    camera.onvifChannel = data.onvifChannel;
    camera.onvifSubStream = data.onvifSubStream;
    camera.autoRtspUrl = data.autoRtspUrl;
    camera.rtspUrl = data.rtspUrl;
    camera.scanAreaId = data.scanAreaId;
    camera.serialNumber = data.serialNumber;
    camera.cameraType = data.cameraType;
  
    onSave(camera);
  };
  
  const cameraType = watch('cameraType');
  const onvifChannel = watch('onvifChannel');
  const onvifSubStream = watch('onvifSubStream');
  const address = watch('address');
  const autoRtspUrl = watch('autoRtspUrl');
  const manufacturer = watch('manufacturer');


  const allowAutoRtspUrl = (cameraType === CameraType.ONVIF && manufacturer.toLowerCase() === "amcrest");
  
  const rtspExample: string = `rtsp://${address}:554/cam/realmonitor?channel=${onvifChannel}&subtype=${onvifSubStream}&unicast=true&proto=Onvif`

  if (allowAutoRtspUrl && autoRtspUrl) {
    setValue("rtspUrl", rtspExample);
  }
  
  /*
  <FormControl fullWidth margin="dense" variant="filled">
            <InputLabel id="scan-area-label">Location</InputLabel>
            <Controller
              name="scanAreaId"  // The name of the field in form state
              control={control}
              render={({ field }) => (
                <Select labelId="scan-area-label" id="scan-area-select" label="Scan Area" {...field}>
                  {site.scanAreas?.map((a) => (
                    <MenuItem id={a.scanAreaId} key={a.scanAreaId} value={a.scanAreaId}>{a.scanAreaName}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>*/

  return (
    <Dialog open={true} onClose={handleCancelClick}>

      <DialogTitle><Tooltip title={camera.cameraId ?? ""}><span>{title}</span></Tooltip></DialogTitle>

      <form onSubmit={handleSubmit(handleSaveClick)}>

      <DialogContent>
        
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>  

          <FormLabel component="legend">Site Config</FormLabel>

          <TextField id="cameraName" label="Camera Name" margin="dense" type="string" fullWidth required
            {...register("cameraName", { required: "Camera Name is required"})}
            error={!!(touchedFields["cameraName"] && errors["cameraName"])}
            helperText={touchedFields["cameraName"] && errors.cameraName?.message}   
          />          

          <FormControl fullWidth margin="dense" variant="filled">
            <InputLabel id="hub-label">Video Hub (BVR)</InputLabel>
            <Controller
              name="hubId"
              control={control}
              render={({ field }) => (
                <Select labelId="hub-label" id="hub-select" label="Hub" 
                  {...field}>
                  {site.hubs?.map((h) => (
                    <MenuItem id={h.hubId} key={h.hubId} value={h.hubId}>{h.name ? h.name : h.hubId}</MenuItem>
                  ))}
                </Select>
              )}
            />            
          </FormControl>
          
        </Paper>

        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>  
          <FormLabel component="legend">Hardware Info</FormLabel>
          <FormControl fullWidth margin="dense" variant="filled">
            <InputLabel id="camera-type-label">Camera Type</InputLabel>
            <Controller
              name="cameraType"
              control={control}
              render={({ field }) => (
                <Select labelId="camera-type-label" id="camera-type-select" label="Camera Type" {...field}>
                  <MenuItem id="onvifType" key="ONVIF" value={CameraType.ONVIF}>ONVIF</MenuItem>
                  <MenuItem id="rtspType" key="RTSP" value={CameraType.RTSP}>Generic RTSP</MenuItem>
                </Select>
              )}
            />
          </FormControl> 
          {cameraType === CameraType.ONVIF && <>
            <TextField id="manufacturer" label="Manufacturer" margin="dense" type="string" fullWidth
              {...register("manufacturer")}
            />
            <TextField id="model" label="Model" margin="dense" type="string" fullWidth
              {...register("model")}
            />
            <TextField id="serialNumber" label="Serial Number" margin="dense" type="string" fullWidth
              {...register("serialNumber")}
            />
          </>}
     
        </Paper>
        
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
          <FormLabel component="legend">Rtsp Stream</FormLabel>

          {allowAutoRtspUrl && 
            <FormControlLabel
              control={
                <Controller
                  name="autoRtspUrl"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              }
              label="Automatic rtsp stream url"
            />
          }

          {allowAutoRtspUrl && autoRtspUrl && <>

            <TextField id="address" label="IP Address" margin="dense" type="string" fullWidth required
              {...register("address", {
                required: "Email (or user id) is required",
                pattern: {
                  // Regex for an an IPV4 address
                  value: /^([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/,
                  message: "Must be a valid IP Address"
                }
              })} 
              error={!!(touchedFields["address"] && errors["address"])}
              helperText={touchedFields["address"] && errors.address?.message}                        
            />          
          
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="onvifChannel" label="ONVIF Channel" margin="dense" type="number" required
                  {...register("onvifChannel", {
                    required: "ONVIF Channel is required",
                    min: { value: 0, message: "Value must be non-negative" }
                    }
                  )}
                  variant="outlined" />
              </Grid>
              <Grid item xs={6}>
                <TextField id="onvifSubStream" label="ONVIF SubStream" margin="dense" type="number" required
                  {...register("onvifSubStream", {
                    required: "ONVIF SubStream is required",
                    min: { value: 0, message: "Value must be non-negative" }
                    }
                  )}
                />
              </Grid>              
            </Grid>

          </>}         

          <TextField id="rtspUrl" label="rtsp Stream Url" autoFocus margin="dense" type="string" fullWidth disabled={autoRtspUrl}
            {...register("rtspUrl", { required: "rtsp Stream Url is required"})}
            error={!!(touchedFields["rtspUrl"] && errors["rtspUrl"])}
            helperText={touchedFields["rtspUrl"] && errors.rtspUrl?.message} 
          />
          
        </Paper>        

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancelClick} color="secondary">Cancel</Button>
        <Button variant="outlined" onClick={handleSubmit(handleSaveClick)} color="primary" disabled={!isValid}>{saveButtonLabel}</Button>
      </DialogActions>
      </form>
    </Dialog>
  )
}

export default observer(CameraDialog);