import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"

import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSiteNavigator, useSitePageBreadcrumbs } from "./SitePage";
import OccupancyChart from "../charts/OccupancyChart";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TrackedObjectEvent from "../models/TrackedObjectEvent";
import AddchartIcon from '@mui/icons-material/Addchart';
import DeleteIcon from '@mui/icons-material/Delete';
import { action, makeObservable, observable } from "mobx";
import { GUID } from "@babylonjs/core";
import ShiftChart from "../charts/ShiftChart";

import EngagementChart from "../charts/EngagementChart";
import TriggerPicker from "../components/TriggerPicker";
import TimeSpanControl from "../components/TimeSpanControl";
import { Console } from "node:console";

enum ChartType { WeeklyOccupancy, WeeklyStaffing, WeeklyDwellTime}

class ChartConfig {
  @observable chartType: ChartType;
  @observable chartId: string;

  @observable hasData: boolean;
  
  @observable startDate: Dayjs;
  @observable endDate: Dayjs;
  @observable region: string;
  
  pendingStartDate: Dayjs;
  pendingEndDate: Dayjs;
  pendingRegion: string;
  
  constructor (chartType: ChartType, date: Dayjs) { 
    this.chartType = chartType;
    this.hasData = false;
    this.chartId = GUID.RandomId();    

    this.pendingStartDate = date;
    this.pendingEndDate = date;
    this.pendingRegion = "";

    this.startDate = date;
    this.endDate = date;        
    this.region = "";
    
    makeObservable(this);
  }

  @action
  setChartType(chartType: ChartType) {
      this.chartType = chartType;
      this.hasData = false;
  }
  
  @action
  setStartDate(date: Dayjs) {
      this.pendingStartDate = date;
  }

  @action
  setEndDate(date: Dayjs) {
      this.pendingEndDate = date;
  }
  
  @action
  setRegion(region: string) {
      this.pendingRegion = region;
  }
  
  @action
  refresh() {
    this.startDate = this.pendingStartDate;
    this.endDate = this.pendingEndDate;
    this.region = this.pendingRegion;
    this.hasData = true;
    console.log(this.startDate);
    console.log(this.endDate);
  }
};

const ChartContainer: React.FC<{chartConfig: ChartConfig, showRemoveButton: boolean,  onAddChart: ()=>void, onRemoveChart: (chartConfig: ChartConfig)=>void}> = observer(({chartConfig, showRemoveButton, onAddChart, onRemoveChart}) => {
  
  const handleChartTypeChange =  (value: ChartType) => {
    chartConfig.setChartType(value);
  };

  const handleGo = (from: Dayjs, to: Dayjs) => {
    console.log(`From: ${from ? from.toISOString() : 'N/A'}`);
    console.log(`To: ${to ? to.toISOString() : 'N/A'}`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" display="flex"  flexDirection="column">

        <Box component="div" gap={2} sx={{ marginLeft: '10px',  display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '10px' }}>
          <FormControl>
            <InputLabel id="chart-type-label">Chart Type</InputLabel>
              <Select value={chartConfig.chartType} onChange={(e) => handleChartTypeChange(e.target.value as ChartType)} label="Chart Type">
                  <MenuItem value={ChartType.WeeklyOccupancy}>Occupancy</MenuItem>
                  <MenuItem value={ChartType.WeeklyDwellTime}>Dwell Time</MenuItem>
                  <MenuItem value={ChartType.WeeklyStaffing}>Staffing</MenuItem>
              </Select>
          </FormControl>

          {chartConfig.chartType !== ChartType.WeeklyDwellTime &&
            <DatePicker 
              label="Date"
              value={chartConfig.startDate}
              onChange={(newValue) => {
                chartConfig.setStartDate(newValue!);
              }}
            />
          }

          {chartConfig.chartType === ChartType.WeeklyDwellTime &&
            <>            
            <TimeSpanControl
              onGo={(startDate: Dayjs, endDate: Dayjs) => {
                chartConfig.setStartDate(startDate);
                chartConfig.setEndDate(endDate);
                chartConfig.refresh();
              }}
              goLabel="Refresh"
              allowCustom={true}
              initialFromDate={chartConfig.startDate}
              initialToDate={chartConfig.startDate}
              initialDuration="Week"
              granularity="large"
            >
              <TriggerPicker
                onChange={(newValue) => {
                    chartConfig.setRegion(newValue);
                }}
              />
            </TimeSpanControl>
            
            
            </>
          }

          {chartConfig.chartType !== ChartType.WeeklyDwellTime &&
            <Button id="refresh" onClick= {() => { chartConfig.refresh()}} size="small">Refresh</Button>
          }

          <Button id="addChart" onClick={() => { onAddChart() }} size="small"><AddchartIcon/></Button>
          {showRemoveButton &&
            <Button id="removeChart" onClick={() => { onRemoveChart(chartConfig) }} size="small"><DeleteIcon/></Button>
          }
        </Box>        

        {chartConfig.hasData ?
          <Box component="div" >
            {chartConfig.chartType === ChartType.WeeklyOccupancy &&
              <OccupancyChart date={chartConfig.startDate}/>          
            }

            {chartConfig.chartType === ChartType.WeeklyDwellTime &&
              <EngagementChart startDate={chartConfig.startDate} endDate={chartConfig.endDate} region={chartConfig.region}/>
            }

            {chartConfig.chartType === ChartType.WeeklyStaffing &&
              <ShiftChart date={chartConfig.startDate}/>
            }
          </Box>
       
      :
        <Box component="div" sx={{ marginLeft: '10px',  display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '10px'}}>
          <Typography>Click refresh to render chart</Typography> 
        </Box>
      }
      </Box>
    </LocalizationProvider>
  )
});

const Dashboard : FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Dashboard");
  const [chartConfigs, setChartConfigs] = useState<ChartConfig[]>([]);  
  
  const [trackedObjectsEvents, setTrackedObjectEvents] = useState<TrackedObjectEvent[]>([]);  
  
  useEffect(() => {
    setChartConfigs( [new ChartConfig(ChartType.WeeklyStaffing, dayjs().startOf('week'))] );  
  }, []);

  function addChart() {
    const newChartConfigs = [...chartConfigs, new ChartConfig(ChartType.WeeklyStaffing, dayjs().startOf('week'))];
    setChartConfigs(newChartConfigs);
  }

  function removeChart(chartConfig: ChartConfig) {
    chartConfigs.splice(chartConfigs.indexOf(chartConfig), 1);
    setChartConfigs([...chartConfigs]);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" overflow="clip" display="flex" height="100%" flexDirection="column">

        <Box component="div" display="flex" width="100%" flexDirection="row" >
          <BreadcrumbBar breadcrumbs={breadcrumbs} />
          <Box component="div" sx={{ flexGrow: 1 }} />
        </Box>

        <Box component="div"  sx={{ flexGrow: 1, overflowY: 'auto'}}>
        {chartConfigs.map((chartConfig, index) => {
            return <ChartContainer key={`chart-${chartConfig.chartId}`} chartConfig={chartConfig} showRemoveButton={chartConfigs.length > 1} onAddChart={addChart} onRemoveChart={removeChart}/>          
        })}
        </Box>

      </Box>
      </LocalizationProvider>
  )
})

export default Dashboard;

/*
  <DateTimePicker 
                label="Date"
                value={chartConfig.date}
                onChange={(newValue) => {
                  chartConfig.setStartDate(newValue!);
                }}
              />
              <DateTimePicker 
                label="EndDate"
                value={chartConfig.endDate}
                onChange={(newValue) => {
                  chartConfig.setEndDate(newValue!);
                }}
              />
                            */