import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Box, Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import { ScanState } from "../models/ScanState";
import { useSiteNavigator, useSitePageBreadcrumbs, useSite } from "./SitePage";
import NoAccess from "../components/NoAccess";
import TitleToolbar from "../components/TitleToolbar";

const Scans : FunctionComponent = observer(() => {
  //const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Scans");
  const site = useSite();

    //const [deleteScanDialogOpen, setDeleteScanDialogOpen] = useState(false);
    //const [scanStatusDialogOpen, setScanStatusDialogOpen] = useState(false);
    
    // const handleDeleteClick = () => {
    //   setDeleteScanDialogOpen(true);
    // };

    // const handleDeleteClose = () => {
    //   setDeleteScanDialogOpen(false);
    // };

    // const handleScanStatusClick = () => {
    //   setScanStatusDialogOpen(true);
    // };

    // const handleScanStatusClose = () => {
    //   setScanStatusDialogOpen(false);
    // };

    // const handleRestartClick = () => {
    //   //siteBionicsApplcation.restartCurrentScan();
    // };

  
  return (
    <>

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleToolbar title="Scans" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
        <>
          <Button variant="outlined" size="small" disabled={true}>Get Status</Button>
          <Button variant="outlined" size="small" disabled={true}>Restart</Button>
          <Button variant="outlined" size="small" disabled={true}>Delete</Button>
          {/* <DeleteScanDialog open={deleteScanDialogOpen} onClose={handleDeleteClose}/> */}
          {/* <ScanStatusDialog open={ScanStatusDialogOpen} onClose={handleScanStatusClose}/>   */}
        </>
      </TitleToolbar> 

      <Box component="div" sx={{height: "10pt"}}/>
      
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Scan Id</TableCell>
              <TableCell align="left">Scan Area</TableCell>
              <TableCell align="left">Version</TableCell>
              <TableCell align="left">Processing Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {siteNavigator.currentScanArea?.scans?.toSorted((lhs, rhs) => (rhs.scanVersion - lhs.scanVersion)).map((s) => (
              <TableRow key={s.scanId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={s === siteNavigator.currentScan}
                    onClick={ () =>{siteNavigator.setCurrentScan(s)}}
                  />
                </TableCell>
                <TableCell component="th" scope="row"> {s.scanId} </TableCell>
                <TableCell> {s.scanId} </TableCell>
                <TableCell> {s.scanVersion.toString()} </TableCell>
                <TableCell> {ScanState[s.scanState]} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
})

export default Scans;

