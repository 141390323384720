import { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Typography, Button, CircularProgress, Backdrop } from "@mui/material";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import TitleSimple from "../components/TitleSimple";
import { ShiftUpdate } from "../models/Shift";
import SiteNavigator from "../models/SiteNavigator";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";

const SiteShifts: FunctionComponent = observer(() => {
  const [file, setFile] = useState<File | null>(null);
  const [shifts, setShifts] = useState<any[]>([]);
  const [loaded, setLoaded] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const site = useSite();
  const siteBionicsApplcation = useSiteBionicsApplication();
  const account = site.account;
  const breadcrumbs = useSitePageBreadcrumbs("Shifts");

  useEffect(() => {
    account.loadRoleMapAsync();
  }, [account]);

  // Function to handle file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  // Function to parse the CSV file
  const handleSubmit = async () => {
    setLoaded(null);
    setShifts([]);
    setLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        const lines = text.split("\n");

        const parsedShifts: ShiftUpdate[] = [];

        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].trim();
          if (!line) continue;          
          const parts = line.split(",");
          if (parts.length === 5) {

            parsedShifts.push(new ShiftUpdate(
              parts[0].trim(),
              parts[1].trim(),
              parts[2].trim(),
              parts[3].trim(),
              parts[4].trim()
            ));            
          }
        };
        setShifts(parsedShifts);
        siteBionicsApplcation.service.updateShiftsAsync(site, parsedShifts).then((count: number) => {
          setLoaded(count);
          setLoading(false);
        });
      };
      reader.readAsText(file);

    }
  };

  return (
    <>
      {loading &&
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open><CircularProgress color="inherit" /></Backdrop>
      }
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleSimple
        title="Shifts"
        sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}
      />

      <Box component="div" sx={{ paddingX: "10pt", overflow: "auto" }}>

        {/* File input for CSV upload */}
        <Typography sx={{ marginTop: "20pt" }}>Upload a CSV file. Shift is a row. Row is firstname,lastname,employee-id,start,end.  Start and end are YYYY-MM-DDTHH:MM in local time of the site.</Typography>
        <input type="file" accept=".csv" onChange={handleFileUpload} />
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "10pt" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>

        {/* Display the parsed shifts */}
        {shifts.length > 0 && (
          <Box component="div" sx={{ marginTop: "20pt" }}>
            <Typography variant="h6">Parsed Shifts from the file: {shifts.length}</Typography>
            {shifts.map((shift, index) => (
              <Box component="div" key={index} sx={{ marginBottom: "10pt" }}>
                <Typography>
                  {shift.firstName},{shift.lastName},{shift.employeeId},{shift.startTime},{shift.endTime}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {loaded != null &&
          <Box component="div" sx={{marginTop: "20pt"}}>
            <Typography variant="h6">Processed on backend: {loaded}</Typography>
          </Box>
        }
      </Box>
    </>
  );
});

export default SiteShifts;