
import React from "react";
import { createRoot } from 'react-dom/client';
import { configure } from 'mobx';
import "./styles/index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { SiteBionicsApplication} from "./models/SiteBionicsApplication";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { CachedUsersProvider } from './models/CachedUsersContext';
import { Components, Theme } from '@mui/material/styles';
import ReactGA from "react-ga4";
import App from './pages/App';

const msalInstance = new PublicClientApplication(msalConfig);


msalInstance.initialize().then(() => {

    const siteBionicsApplication = SiteBionicsApplication.getInstance();
    siteBionicsApplication.service.msalInstance = msalInstance;

    const ga_id = process.env.REACT_APP_GA ?? "G-8G3PGSV0S2";
    ReactGA.initialize(ga_id);
    
    // configure mobx to enforce observables and reactions
    configure({ enforceActions: 'always',});

    const container = document.getElementById('root');
    const root = createRoot(container!);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
        // Ugly; but fixes a usability problem with the time picker in date controls
        components: {
            MuiMultiSectionDigitalClock: {
                styleOverrides: {
                  root: {
                    '&&': {
                      '.MuiList-root': {
                        width: '75px',
                      },
                    },
                  },
                },
              },
            } as Components<Omit<Theme, 'components'>>,
    });

    //It's recommended to wrap most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}> 
                <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <CachedUsersProvider> 
                                              
                            <App/>
                        
                    </CachedUsersProvider>
                </ThemeProvider>
            </MsalProvider>
        </React.StrictMode>
    );
});