import { useState, FunctionComponent, useRef, useEffect } from "react";
import { observer } from "mobx-react"

import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Slider, Stack, Switch, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { VideoPlayer } from "../components/VideoPlayer";
import SiteViewerScene from "../components-3d/SiteViewerScene";
import { SelectionManager, SelectionManagerContext } from "../util/SelectionManager";
import TrackedObject from "../models/TrackedObject";
import Tracklet, { TrackletType } from "../models/Tracklet";
import { useSiteNavigator, useSitePageBreadcrumbs, useSite } from "./SitePage";
import Timeline from "../components/Timeline";
import DurationPicker from "../components/DurationPicker";
import Camera from "../models/Camera";
import { FragmentUploadPriority } from "../models/FragmentUploadPriority";
import TrackletsLayer from "../components-3d/TrackletsLayer";
import FilterDialog, { FilterEntry } from "../components/FilterDialog";
import TrackedObjectDetail from "../components/TrackedObjectDetail";
import BoundingBoxOverlay from "../components/BoundingBoxOverlay";
import { FastForwardOutlined, FastRewindOutlined, FirstPageOutlined, LastPageOutlined, Refresh } from "@mui/icons-material";
import { has } from "mobx";

const Tracking: FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const site = useSite();
  const breadcrumbs = useSitePageBreadcrumbs("Tracking");
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoDivRef = useRef<HTMLDivElement>(null);

  const [selectionManager] = useState(new SelectionManager());
  const [playListUrl, setPlayListUrl] = useState<string>("");
  const [playFromOffset, setPlayFromOffset] = useState<number>(0);
  const [duration, setDuration] = useState<number>(1);
  const [showTrackletsInTimeline, setShowTrackletsInTimeline] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterEntry[]>([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    
  // This effect runs only once when the component is mounted.
  // As a side effect the first scan area is selected and event listeners are added to the video container.
  useEffect(() => {
    const videoContainer = videoContainerRef.current;
    if (videoContainer) {
      videoContainer.addEventListener('click', handleClick);
    }
    siteNavigator.site.loadAreasAsync().then((scanAreas) => {
      if (scanAreas?.length) {
          siteNavigator.setCurrentScanArea(scanAreas[0]);
      }
    });
    siteNavigator.site.loadCamerasAsync().then((cameras) => {
      if (cameras?.length) {
        siteNavigator.setCurrentCameraById(cameras[0].cameraId);
      }
    });

    return () => {
      if (videoContainer) {
        videoContainer.removeEventListener('click', handleClick);
      }
    };
  }, []);

  // This effect is run when the current scan area changes (from above effect or UI).
  // It loads the scan area layout for the selected scan area.
  useEffect(() => {
    siteNavigator.currentScan?.loadScanAreaLayoutAsync().then((scanAreaLayout) => {
      
      siteNavigator.setCurrentScanAreaLayout(scanAreaLayout);

      const filterEntries: FilterEntry[] = scanAreaLayout?.triggers.map(trigger => ({
        tag: trigger.name, operation: 'None', duration: 0})) ?? [];
      setFilters(filterEntries);

    })
  }, [site, siteNavigator, siteNavigator.currentScan]);

  const handleVideoTimeOffsetUpdate = (event: Event, newValue: number | number[]) => {
    // console.log(siteNavigator.videoTime, newValue, siteNavigator.videoTime.add(newValue as number, "second"));
    siteNavigator.currentCamera?.setVideoTimeOffset(newValue as number);
    siteNavigator.setCurrentTime(siteNavigator.videoTime.add(newValue as number, "second"));
  };
  
  const handleVideoTimeUpdate = (videoTime: Dayjs) => {
    if (siteNavigator.currentCamera) {
      siteNavigator.setCurrentTime(videoTime.add(siteNavigator.currentCamera.videoTimeOffset, "second"));
      siteNavigator.setVideoTime(videoTime);
    }
  };

  function getClickedTracklet(event: MouseEvent) : Tracklet | undefined {
    const videoContainer = videoContainerRef.current;
    if (videoContainer) {
      const rect = videoContainer.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;
      //console.log(`x: ${x}, y: ${y}`);
      
      return siteNavigator.currentTracklets?.find(tracklet => {
        if (tracklet.camera?.cameraId === siteNavigator.currentCamera?.cameraId) {
          const closestDataPoint = tracklet.findClosestDataPoint(siteNavigator.currentTime.toDate());
          if (closestDataPoint) {
            const left = closestDataPoint.bbox[0];
            const top = closestDataPoint.bbox[1];
            const width = closestDataPoint.bbox[2];
            const height = closestDataPoint.bbox[3];
            //console.log(`x: ${x}, y: ${y}, left: ${left}, top: ${top}, width: ${width}, height: ${height}`);
            
            if (x >= left && x <= left + width && y >= top && y <= top + height) {
              //console.log(`>>>>> x: ${x}, y: ${y}, left: ${left}, top: ${top}, width: ${width}, height: ${height}`);
              return true;
            }
          }
        }
        return false;
      });
    }
    return undefined;
  }

  function handleClick(event: MouseEvent) {
    const selectedTracklet = getClickedTracklet(event)
    if (selectedTracklet) {
      event.stopPropagation(); // Stop the event from propagating to the VideoPlayer
      selectTrackedObject(selectedTracklet.trackedObject!);
    }
  };

  const defaultVideoDuration = 60000; // we start out watching a 1 minute video

  function handleCameraChange(event: SelectChangeEvent<string>): void {
    siteNavigator.setCurrentCameraById(event.target.value);
    if (siteNavigator.currentCamera) {
      showVideoForCamera(siteNavigator.currentCamera, 
        siteNavigator.videoStartTime.toDate(), 
        defaultVideoDuration, 
        siteNavigator.videoTime.toDate());
    }
  }
 
  enum PlayFrom { Current, Start, End};

  function selectTrackedObject(trackedObject: TrackedObject, playFrom: PlayFrom = PlayFrom.Current) {
    
    siteNavigator.setCurrentTrackedObject(trackedObject);
    siteNavigator.setCurrentTracklet(undefined);

    let currentTime = siteNavigator.currentTime.toDate();
    let currentVideoTime = siteNavigator.videoTime.toDate();
    let videoStartTime = siteNavigator.videoStartTime.toDate();
    
    // the tracked object spans the current time so we can show it at the same current time
    if (trackedObject.startTime <= currentTime && trackedObject.endTime >= currentTime && playFrom === PlayFrom.Current) {
        
        // find the tracklets that spans the current time  
        let candidateTracklets = trackedObject.tracklets.filter((t)=>
            t.trackletType === TrackletType.CameraDetection 
            && t.startTime <= currentTime && t.endTime >= currentTime)
          .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

        // lets try to get a tracklet from the current camera that spans the current time
        let candidateTrackletsSameCamera = candidateTracklets.filter((t)=>
            t.camera === siteNavigator.currentCamera);
        
        // we have a tracklet from the current camera that spans the current time
        if (candidateTrackletsSameCamera.length === 1) {
          let _tracklet = candidateTracklets[0];
          // showing video from the same camera so we don't need to do anything here since the 
          // video is already playing from the same camera for a timespan that includes the current time
          // we have already set the new selected tracked object above
          return;
        }
        else if (candidateTracklets.length > 0) {
          let tracklet = candidateTracklets[0];
          // showing video from a different camera so we reset the duration so we don't trigger too much new download
          showVideoForCamera(tracklet.camera!, videoStartTime, defaultVideoDuration, currentTime);
          return;
        }
    } else if (playFrom === PlayFrom.End) {
      // asking to play from the end of the tracked object
      let candidateTracklets = trackedObject.tracklets.filter((t)=>
        t.trackletType === TrackletType.CameraDetection 
        && t.startTime <= siteNavigator.endTime.toDate() && t.endTime >= siteNavigator.startTime.toDate())
      .sort((a, b) => b.endTime.getTime() - a.endTime.getTime());

      if (candidateTracklets.length > 0) {
        let tracklet = candidateTracklets[0];
        let clippedStartTime = dayjs(Math.max(tracklet.endTime.getTime() - 3000, siteNavigator.startTime.toDate().getTime()));
        showVideoForCamera(tracklet.camera!, clippedStartTime.toDate(), defaultVideoDuration, clippedStartTime.toDate());
        return;
      }
    } else {
      // the tracked object doesn't span the current time so we show it from the beginning (or we asked to see it from the start)
      // find the starting tracklet in our overall time range
      let candidateTracklets = trackedObject.tracklets.filter((t)=>
        t.trackletType === TrackletType.CameraDetection 
        && t.startTime <= siteNavigator.endTime.toDate() && t.endTime >= siteNavigator.startTime.toDate())
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

      if (candidateTracklets.length > 0) {
        let tracklet = candidateTracklets[0];
        showVideoForCamera(tracklet.camera!, tracklet.startTime, defaultVideoDuration, tracklet.startTime);
        return;
      }
    }
   
  };

  const selectTracklet = (tracklet: Tracklet) => {
    if (tracklet.camera) {
        siteNavigator.setCurrentTrackedObject(tracklet.trackedObject);
        siteNavigator.setCurrentTracklet(tracklet);
        showVideoForCamera(tracklet.camera, tracklet.startTime, defaultVideoDuration, tracklet.startTime);
    }
  };

  function showVideoForCamera(camera: Camera, startTime: Date, duration: number, currentTime: Date) {
    const clippedStartTime = dayjs(Math.max(startTime.getTime(), siteNavigator.startTime.toDate().getTime()));
    const clippedEndTime = dayjs(Math.min(startTime.getTime() + duration, siteNavigator.endTime.toDate().getTime()));
    siteBionicsApplication.service.postUploadVideoRequest(
      siteNavigator.site.account.accountId, siteNavigator.site.siteId, camera.cameraId,
      FragmentUploadPriority.UserHigh, clippedStartTime, clippedEndTime)
      .then((playlistStartTime) => {
        if (playlistStartTime) {
          //todo: offset all video times by camera offset
          siteNavigator.setCurrentCamera(camera);
          siteNavigator.setVideoStartTime(playlistStartTime);
          siteNavigator.setVideoEndTime(clippedEndTime);
          siteNavigator.setVideoTime(dayjs(currentTime));
          const offset = (currentTime.getTime() - playlistStartTime.toDate().getTime()) / 1000;
          
          console.log(`startTime: ${startTime.toTimeString()}\r\nclippedStartTime: ${clippedStartTime.toDate().toTimeString()}\r\nplaylistStartTime: ${playlistStartTime.toDate().toTimeString()}\r\ncurrentTime: ${currentTime.toTimeString()}\r\noffset: ${offset}`);

          siteBionicsApplication.service.videoPlayListUrl(
            siteNavigator.site.account.accountId, siteNavigator.site.siteId, camera.cameraId,
            clippedStartTime, clippedEndTime).then((url) => {
              setPlayFromOffset(offset > 0 ? offset : 0);
              setPlayListUrl(url)
            });
        }
    });
  }

  function runQuery(): void {
    siteBionicsApplication.service.fetchTrackedObjectWithTrackletDataAsync(siteNavigator.site,
      siteNavigator.startTime!, siteNavigator.endTime!).then(([trackedObjects, tracklets]) => {
        siteNavigator.setCurrentTrackedObjects(trackedObjects.sort((a, b) => a.startTime.getTime() - b.startTime.getTime()));
        siteNavigator.setCurrentTracklets(tracklets);
      });
  }


  function filterTrackedObjects(trackedObjects: TrackedObject[], filters: FilterEntry[]): TrackedObject[] {
      return trackedObjects.filter(trackedObject => {
          // Check each filter against the trackedObject
          return filters.some(filter => {
              const { tag, operation, duration } = filter;

              // Retrieve the value of the property from the trackedObject dynamically
              const value = trackedObject.timeEngagedWithTags[tag];

              if (operation === 'None' || value === undefined) {
                  // Skip this filter if no operation or the property is not found in the object
                  return false;
              }

              // Perform the comparison based on the operation type
              if (operation === 'GreaterThan') {
                  return typeof value === 'number' && value > duration;
              } else if (operation === 'LessThan') {
                  return typeof value === 'number' && value < duration;
              }

              return false;
          });
      });
  }

  const handleOpenDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseDialog = () => {
      setFilterDialogOpen(false);
  };

  const handleSaveFilters = (updatedEntries: FilterEntry[]) => {
    setFilters(updatedEntries);
    let filteredTrackedObjects = filterTrackedObjects(siteNavigator.currentTrackedObjects!, updatedEntries);
    siteNavigator.setCurrentTrackedObjects(filteredTrackedObjects)
  };

  function handleShowStart(): void {
    if (siteNavigator.currentTrackedObject) {
      selectTrackedObject(siteNavigator.currentTrackedObject, PlayFrom.Start);
    }
  }

  function handleShowMoreBefore(): void {
    if (!siteNavigator.currentCamera) return;
    const clippedNewStartTime = dayjs(Math.max(siteNavigator.videoStartTime.toDate().getTime() - defaultVideoDuration, siteNavigator.startTime.toDate().getTime()));
    showVideoForCamera(
        siteNavigator.currentCamera,
        clippedNewStartTime.toDate(),
        siteNavigator.videoEndTime.toDate().getTime() - clippedNewStartTime.toDate().getTime(),
        clippedNewStartTime.toDate());
  }

  function handleShowMoreAfter(): void {
    if (!siteNavigator.currentCamera) return;
    const clippedNewEndTime = dayjs(Math.min(siteNavigator.videoEndTime.toDate().getTime() + defaultVideoDuration, siteNavigator.endTime.toDate().getTime()));
    showVideoForCamera(
        siteNavigator.currentCamera,
        siteNavigator.videoStartTime.toDate(),
        clippedNewEndTime.toDate().getTime() - siteNavigator.videoStartTime.toDate().getTime(),
        siteNavigator.videoTime.toDate());
  }

  function handleShowEnd(): void {
    if (siteNavigator.currentTrackedObject) {
      selectTrackedObject(siteNavigator.currentTrackedObject, PlayFrom.End);
    }
  }

  const hasTrackedObjects = siteNavigator.currentTrackedObjects && siteNavigator.currentTrackedObjects.length > 0;
  
  return (
    <SelectionManagerContext.Provider value={selectionManager}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>

        <Box component="div" overflow="hidden" display="flex" height="100%" flexDirection="column">

          {/* breadcrumb bar container */}
          <Box component="div" display="flex" flexDirection="row" >
            <BreadcrumbBar breadcrumbs={breadcrumbs} />
            <Box component="div" sx={{ flexGrow: 1}}/>
          </Box>
          
          {/* toolbar container */}
          <Box component="div" sx={{display: 'flex',  flexDirection: 'row', marginLeft: '10px', marginRight: '10px', marginTop: '2px', marginBottom: "5px"}}>
            {/* toolbar container left*/}
            <Box component="div" sx={{display: 'flex',  flexDirection: 'row', width: "50%", gap: "2px"}}>
                <DateTimePicker
                  label="Start Time"
                  value={siteNavigator.startTime}
                  onChange={(newValue) => {
                    siteNavigator.setStartTime(newValue!);
                    siteNavigator.setEndTime(siteNavigator.startTime!.add(duration, "minute"));
                  }}
                />
                <DurationPicker 
                  onChange={(newDuration) => {
                    setDuration(newDuration);
                    siteNavigator.setEndTime(siteNavigator.startTime!.add(newDuration, "minute"));
                  }}
                />
                <Button variant="outlined" onClick={handleOpenDialog}>Filter</Button>
                {!hasTrackedObjects && 
                  <Button variant="contained" onClick={() => { runQuery() }}>Go</Button> }
                {hasTrackedObjects && 
                  <Button variant="outlined" onClick={() => { runQuery() }} endIcon={<Refresh/>}>Refresh</Button> }
                <FilterDialog
                    open={filterDialogOpen}
                    onClose={handleCloseDialog}
                    entries={filters}
                    onSave={handleSaveFilters}
                />
            </Box>
            {/* toolbar container right*/}
            <Box component="div" sx={{display: 'flex',  flexDirection: 'row', width: "50%"}}>
              <FormControl>
                <InputLabel id="camera-list-label">Camera</InputLabel>
                <Select
                  labelId="camera-list-label"
                  id="camera-list"
                  value={siteNavigator.currentCamera?.cameraId ?? ""}
                  label="Camera"            
                  onChange={handleCameraChange}>
                  {siteNavigator.site.cameras?.map((c) => (
                    <MenuItem key={c.cameraId} value={c.cameraId}>{c.cameraName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box component="div" sx={{ flexGrow: 1}}/>
              <Button variant="outlined" onClick={handleShowStart}><FirstPageOutlined /></Button>
              <Button variant="outlined" onClick={handleShowMoreBefore}><FastRewindOutlined /></Button>
              <Button variant="outlined" onClick={handleShowMoreAfter}><FastForwardOutlined /></Button>
              <Button variant="outlined" onClick={handleShowEnd}><LastPageOutlined /></Button>
            </Box>      
        </Box>

          {/* 2 column container */}
          <Box component="div" overflow="hidden" sx={{ width: '100%', display: 'flex', flexGrow: 1, flexDirection: 'row' }}>
            {/* left column */}
            <Box component="div" id="LeftColumn" ref={videoDivRef} sx={{display: 'flex', flexDirection: 'column', width: "50%"}}  >
              <Box component="div" display={'flex'} sx={{flexgrow: 1, height: "100%", overflow: "hidden"}} position="relative" >
                <SiteViewerScene siteNavigator={siteNavigator}>
                  <TrackletsLayer siteNavigator={siteNavigator} selectTrackedObject={selectTrackedObject} showDetailedTracklets={showTrackletsInTimeline}/>
                </SiteViewerScene>
                <Stack component="div" sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 10, paddingLeft: 2, backgroundColor: '#333333AA'}}>
                  {/* floating controls */}
                  <FormControlLabel control={<Switch size="small" checked={showTrackletsInTimeline} onChange={(event) => setShowTrackletsInTimeline(event.target.checked)}/>} label="Show Tracklets" />
                </Stack>
              </Box>
              <Box component="div" sx={{height:"120", width: '100%', flexGrow: 0, flexShrink: 0}} padding={'5px'}>
                <Timeline/>
              </Box>
            </Box>
            {/* right column */}
            <Box component="div" sx={{display: "flex", flexDirection: "column", width: "50%"}}>
              {/* right top */}
              <Box component="div" id="LeftTop" ref={videoContainerRef} sx={{width: '100%', position: 'relative'}}>
                <VideoPlayer  hlsSrc={playListUrl} startTime={siteNavigator.videoStartTime} playFromOffset={playFromOffset} posterSrc="" onTimeUpdate={handleVideoTimeUpdate} fluid={true}/>
                <BoundingBoxOverlay/>
                <Stack component="div" sx={{ position: 'absolute', top: 10, left: 10, zIndex: 10, backgroundColor: '#333333AA'}}>
                  {/* floating vide time offset selector */}
                  <Typography variant="caption" sx={{paddingX: 2}}>Video Time Offset</Typography>
                  <Slider size="small" sx={{marginX: "5%", width: "90%"}} value={siteNavigator.currentCamera?.videoTimeOffset ?? 0} max={15} min={-15} step={0.01} valueLabelDisplay="auto" onChange={handleVideoTimeOffsetUpdate}/>
                </Stack>
              </Box>
              {/* right bottom */}
              <Box component="div" id="LeftBottom" sx={{minHeight: "200px", flexGrow: "1", width: '100%', overflow: "auto"}}>
                  <TrackedObjectDetail trackedObjects={siteNavigator.currentTrackedObjects!} tracklets={siteNavigator.currentTrackedObject?.tracklets ?? []} selectTracklet={selectTracklet} selectTrackedObject={selectTrackedObject} siteNavigator={siteNavigator} showTracklets={showTrackletsInTimeline}/>
              </Box>
            </Box>
          </Box>
          {/* <Box component="div" sx={{ flexShrink: "0", height: "50px", width: '100%', background: "yellow"}}>
          </Box> */}
        </Box>
      </LocalizationProvider >
    </SelectionManagerContext.Provider>
  )
})

export default Tracking;