import { FunctionComponent, useEffect, useState } from "react";
import { FormControlLabel, Checkbox, Alert, Box, CircularProgress } from '@mui/material';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useSiteNavigator } from "../pages/SitePage";
import {convertTimezoneOnly, hourToLabel} from "../util/Time";

interface HeatmapData {
  hour: number;
  days: Array<{ day: string, dayOfWeek:number, min: number, avg: number, max: number }>;
}

const OccupancyChart: React.FC<{ date: Dayjs}> = observer(({date}) => {
  const [series, setSeries] = useState<any[]>([]);
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const [taggedObjectCounts, setTaggedObjectCounts] = useState<TaggedObjectCounts[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAllHours, setShowAllHours] = useState<boolean>(false);
  const [showMinMax, setShowMinMax] = useState<boolean>(false);
  const siteDate = convertTimezoneOnly(date.startOf('day'), siteNavigator.site.timeZone);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    theme: { mode: 'dark' },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.0000001,
              color: '#999999'
            },
            {
              from: 0.0000001,
              to: 5,
              color: '#FF5555'
            },
            {
              from: 5,
              to: 10,
              color: '#FFFF55'
            },
            {
              from: 10,
              to: 300,
              color: '#55FF55'
            }
          ],
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {        
        if (typeof val === 'number') {
          const seriesData = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
          return seriesData.label;
        } else if (val === null) {
          return "";
        } else {
          return val.toString();
        }
      },
      style: {
        colors: ['#fff']
      }
    },
    legend: { show: false },
    xaxis: {
      type: 'category',
      position: 'top',
      categories: []  // This will be dynamically set
    },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const yValue = series[seriesIndex][dataPointIndex];
  
        // Disable tooltip for null y values
        if (yValue === null || yValue === 0) {
          return ''; // Return an empty string to not display the tooltip
        }
          
        // Default tooltip content if yValue is not null
        return `<div style="padding: 5px;">
              <strong>Average:</strong> ${yValue}<br/>
              <strong>Min:</strong> ${w.config.series[seriesIndex].data[dataPointIndex].min}<br/>
              <strong>Max:</strong> ${w.config.series[seriesIndex].data[dataPointIndex].max}<br/>              
            </div>`;
      }
    }
  });

  useEffect(() => {
    setIsLoading(true);
    siteBionicsApplcation.service.fetchTaggedObjectCountsAsync(siteNavigator.site,
            siteDate, siteDate.add(1, 'week'), "01:00:00", "").then((taggedObjectCounts) => {
            setTaggedObjectCounts(taggedObjectCounts);
            setIsLoading(false);
          });
  }, [date]);

  useEffect(() => {
    const data = taggedObjectCounts.reduce<HeatmapData[]>((acc : HeatmapData[], toc: TaggedObjectCounts) => {
      //const hour = dayjs(toc.time).format('H:00');
      const hour = dayjs(toc.time).hour();
      const day = dayjs(toc.time).format('ddd M/D');

      let hourData = acc.find(h => h.hour === hour);
      if (!hourData) {
        hourData = { hour, days: [] };
        acc.push(hourData);
      }

      let dayData = hourData.days.find(d => d.day === day);
      if (!dayData) {
        dayData = { day, dayOfWeek: dayjs(toc.time).day(), min: toc.min, avg: toc.avg, max: toc.max };
        hourData.days.push(dayData);
      } else {
        dayData.min = Math.min(dayData.min, toc.min);
        dayData.max = Math.max(dayData.max, toc.max);
        dayData.avg = (dayData.avg + toc.avg) / 2; // Averaging the avg value
      }

      return acc;
    }, []);

    const openHours = siteNavigator.site.openHours;
    let minOpenTime = 5000;
    let maxCloseTime = 0;
    for (let i: number = 0; i < 7; i++) {
      minOpenTime = Math.min(minOpenTime, openHours[i]);
      maxCloseTime = Math.max(maxCloseTime, openHours[i + 1]);
    }    
    const seriesData = data.filter((hourData) => {
      return (showAllHours || (hourData.hour * 60 >= minOpenTime && hourData.hour * 60 < maxCloseTime));
    }).map((hourData) => {

      const hourInMinutes = hourData.hour * 60;
      return {
        name: hourToLabel(hourData.hour, false),
        data: hourData.days.map((dayData) => {
          const dayOfWeek = dayData.dayOfWeek;
          const open = openHours ? openHours[dayOfWeek * 2] : 0;
          const close = openHours ? openHours[dayOfWeek * 2 + 1] : 1440;
          if (showAllHours || (hourInMinutes >= open && hourInMinutes <= close)) {
            return {
              x: dayData.day,
              y: dayData.avg, // Color based on avg
              min: dayData.min,
              max: dayData.max,
              label: showMinMax ? dayData.min + " / " + dayData.avg.toFixed(1) + " / " + dayData.max : dayData.avg.toFixed(1)
            }
          } else {
            return {
              x: dayData.day,
              y: null,
              min: null,
              max: null
            }
          }
        })
      }
    }).reverse(); // Reverse the order of the hours, but not the days

    setSeries(seriesData);
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: data[0]?.days.map((day: any) => day.day) || []  // Keep the original x-axis categories
      }
    }));
  }, [date, taggedObjectCounts, showAllHours, showMinMax]);

  return (
    <Box component="div" sx={{ marginLeft: '10px', marginRight: '10px' }}>
      {!isLoading && series.length > 0 &&
        <>
          <FormControlLabel label="Show All Hours"
            control={
              <Checkbox checked={showAllHours} onChange={() => setShowAllHours(prev => !prev)}/>
            }            
          />
          <FormControlLabel label="Show Min/Max"
            control={
              <Checkbox checked={showMinMax} onChange={() => setShowMinMax(prev => !prev)}/>
            }            
          />
          <ReactApexChart options={options} series={series} height={350} type="heatmap" />
        </>
      }
      {!isLoading && series.length == 0 &&
        <Alert severity="warning">No data for this date.</Alert>
      }
      {isLoading &&
        <CircularProgress/>
      }
    </Box>
  );
});

export default OccupancyChart;
