import React, {useState}  from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from '@mui/material';

interface ConfirmationDialogProps {
  
  title?: string;
  confirmationText: string;
  requiredInput?: string;

  confirmButtonLabel?: string;
  onConfirm: () => void;

  hasCancelButton?: boolean;
  cancelButtonLabel?: string;  
  onClose: () => void;  
}


const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  confirmationText,
  requiredInput,
  confirmButtonLabel = 'OK',
  hasCancelButton = true,
  cancelButtonLabel = 'Cancel',
  onClose,
  onConfirm,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const confirmButtonClicked = () => {
    onConfirm();
  };

  const cancelButtonClicked = () => {
    onClose();
  }

  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason === "backdropClick") return;

    onClose();

  }
  
  return (
    <Dialog open={true} onClose={handleCancelLocal}>      
       
      
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Typography>{confirmationText}</Typography>
        {requiredInput &&
          <>
            <Typography>To confirm, type <strong>{requiredInput}</strong> below.</Typography>
            <TextField variant="outlined" onChange={(e) => {setInputValue(e.target.value)}} fullWidth />
          </>
        }
      </DialogContent>
      <DialogActions>
        {hasCancelButton && 
            <Button onClick={cancelButtonClicked} color="secondary">
                {cancelButtonLabel}
            </Button>
        }

        <Button variant="outlined" disabled={inputValue !== requiredInput} onClick={confirmButtonClicked} color="primary" autoFocus>
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
