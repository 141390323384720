import { FunctionComponent, useEffect, useState } from "react";
import { FormControlLabel, Checkbox, Alert, Box, CircularProgress } from '@mui/material';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from "dayjs";
import {convertTimezoneOnly, hourToLabel} from "../util/Time";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import Shift from "../models/Shift";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useSiteNavigator } from "../pages/SitePage";
import { toZonedTime, format } from 'date-fns-tz';

interface HeatmapData {
  hour: number;              // Hour (0-23)
  days: Array<{day: string, dayOfWeek: number, employees: Array<string>}>; // Array of each day, list of employees in that hour in each day
}

const ShiftChart: React.FC<{ date: Dayjs}> = observer(({date}) => {
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAllHours, setShowAllHours] = useState<boolean>(false);
  const siteDate = convertTimezoneOnly(date.startOf('day'), siteNavigator.site.timeZone);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    theme: { mode: 'dark' },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.0000001,
              color: '#999999'
            },            
            {
              from: 0.0000001,
              to: Number.MAX_VALUE,
              color: '#55FF55'
            }
          ],
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {        
        if (typeof val === 'number') {
          return (val === 0) ? "" : val.toString();
        } else if (val === null) {
          return "";
        } else {
          return val.toString();
        }
      },
      style: {
        colors: ['#fff']
      }
    },
    legend: { show: false },
    xaxis: {
      type: 'category',
      position: 'top',
      categories: []  // This will be dynamically set
    },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const yValue = series[seriesIndex][dataPointIndex];
  
        // Disable tooltip for null y values
        if (yValue === null || yValue === 0) {
          return ''; // Return an empty string to not display the tooltip
        }
          
        // Default tooltip content if yValue is not null
        return `<div style="padding: 5px;">
              <strong>Total:</strong> ${yValue}<br>
              <strong>Employees:</strong><br>
              ${w.config.series[seriesIndex].data[dataPointIndex].employees}
            </div>`;
      }
    },
  });


  function transformShiftsToHeatmap(
    shifts: Shift[],
    utcStart: Date,
    utcEnd: Date
  ): HeatmapData[] {
    
    const hourlyDataMap: Map<number, Map<string, string[]>> = new Map(); // { hour : { day : [employees]}}

    // Helper function to get day part of a date
    const getDay = (date: Date) => dayjs(date).format('ddd M/D');
    
    const start = toZonedTime(utcStart, siteNavigator.site.timeZone);
    const end = toZonedTime(utcEnd, siteNavigator.site.timeZone);

    // Populate the hourlyDataMap with employees working in each hour in each day

    // Go through the shifts
    for (let i = 0; i < shifts.length; i++) {
      let shift = shifts[i];
      const utcShiftStart = new Date(shift.startTime);
      const utcShiftEnd = new Date(shift.endTime);

      if (utcShiftStart >= utcShiftEnd) break;      
      
      const shiftStart = toZonedTime(utcShiftStart, siteNavigator.site.timeZone);
      const shiftEnd = toZonedTime(utcShiftEnd, siteNavigator.site.timeZone);
      shiftEnd.setSeconds(shiftEnd.getSeconds() - 1);
      
      const timeZone = siteNavigator.site.timeZone;                  
 
      // Start at the shift start; going till the end or the end of the range
      while (shiftStart < shiftEnd && shiftStart < end) {

        // Current hour being processed
        let hour = shiftStart.getHours();

        // Current day
        let currentDay = getDay(shiftStart);
  
        // Ensure the map has these entries
        if (!hourlyDataMap.has(hour)) {
          hourlyDataMap.set(hour, new Map());
        }
  
        const dayMap = hourlyDataMap.get(hour)!;
        if (!dayMap.has(currentDay)) {
          dayMap.set(currentDay, []);
        }

        // Add to the map
        const entry = shift.employeeId + " " + shift.firstName + " " + shift.lastName;
        if (!dayMap.get(currentDay)!.includes(entry)) {
            dayMap.get(currentDay)!.push(entry);
        }

        // Move to the next hour
        shiftStart.setHours(shiftStart.getHours() + 1);        
      }      
    }

    // Now convert the hourlyDataMap to the heatmapData needed to render

    const heatmapData: HeatmapData[] = [];

    
    // Loop through each hour of the day (0 to 23)
    for (let hour = 0; hour < 24; hour++) {
        
        const dayData: Array<{ day: string, dayOfWeek: number, employees: Array<string>}> = [];
        const dayMap = hourlyDataMap.get(hour) || new Map();

        // Loop through each day in the range
        for (let date = new Date(start); date < end; date.setDate(date.getDate() + 1)) {

            const dayOfWeek = dayjs(date).day();
            const day = getDay(date);
            const employees = dayMap.get(day) || [];            

            dayData.push({day, dayOfWeek, employees});
        }

        heatmapData.push({
            hour: hour,
            days: dayData
        });
    }

    return heatmapData;
  }

  useEffect(() => {
    setIsLoading(true);
    
    siteBionicsApplcation.service.fetchShiftsAsync(siteNavigator.site, siteDate.utc(), siteDate.add(1, 'week').utc()).then((shifts) => {
      var hmd = transformShiftsToHeatmap(shifts, siteDate.utc().toDate(), siteDate.add(1, 'week').utc().toDate() );
      setHeatmapData(hmd);
      setIsLoading(false);
    });       
  }, [date]);
  
  useEffect(() => {
    const data = heatmapData;
    
    let minStaffedTime = Number.MAX_SAFE_INTEGER;
    let maxStaffedTime = Number.MIN_SAFE_INTEGER;

    for (const hourData of data) {
      const hour = hourData.hour;
      for (const day of hourData.days) {
        if (day.employees.length > 0) {
          minStaffedTime = Math.min(minStaffedTime, hour * 60);
          maxStaffedTime = Math.max(maxStaffedTime, hour * 60);
        }
      }      
    }
    
    // Only show the hours the user wants to see
    const seriesData = data.filter((hourData) => {
      return (showAllHours || (hourData.hour * 60 >= minStaffedTime && hourData.hour * 60 <= maxStaffedTime));
    })
    // Render the visible hours
    .map((hourData) => {
      
      const hourInMinutes = hourData.hour * 60;
      
      return {
        name: hourToLabel(hourData.hour, false),

        // Each day
        data: hourData.days.map((dayData) => {  
                    
          if (showAllHours || (hourInMinutes >= minStaffedTime && hourInMinutes <= maxStaffedTime)) {
            return {
              x: dayData.day,
              y: dayData.employees.length,              

              // Each employee (for the hover)
              employees: dayData.employees.join("<br/>")
            }
          } else {
            return {
              x: dayData.day,
              y: null
            }
          }
        })
      }
    }).reverse(); // Reverse the order of the hours, but not the days

    setSeriesData(seriesData);
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: data[0]?.days.map((day: any) => day.day) || []  // Keep the original x-axis categories
      }
    }));
  }, [date, heatmapData, showAllHours]);

  return (
    <Box component="div" sx={{ marginLeft: '10px', marginRight: '10px' }}>
      {!isLoading && seriesData.length > 0 &&
        <>
          <FormControlLabel
            control={
              <Checkbox checked={showAllHours} onChange={() => setShowAllHours(prev => !prev)}/>
            }
            label="Show Unstaffed Hours"
          />
          <ReactApexChart options={options} series={seriesData} height={350} type="heatmap" />
        </>
      }
      {!isLoading && seriesData.length == 0 &&
        <Alert severity="warning">No data for this date.</Alert>
      }
      {isLoading &&
        <CircularProgress/>
      }
    </Box>
  );
});

export default ShiftChart;
