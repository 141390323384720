import React, { useEffect, useState } from 'react';
import  {SxProps} from '@mui/system';

import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { useSiteNavigator } from '../pages/SitePage';
import TriggerEntity from '../models/layout/TriggerEntity';

interface TriggerPickerProps {
  onChange: (value: string) => void;
  sx?: SxProps;
}

const TriggerPicker: React.FC<TriggerPickerProps> = ({ sx, onChange }) => {
  const [selectedTrigger, setSelectedTrigger] = useState<string>("");
  const siteNavigator = useSiteNavigator();
  const [triggers, setTriggers] = useState<string[]>([]);
  
  useEffect(() => {
    siteNavigator.site.loadAreasAsync().then((scanAreas) => {
      if (scanAreas && scanAreas.length > 0) {
        scanAreas[0].loadScansAsync().then((scans) => {
          if (scans && scans.length > 0) {
              let scan = scans[scans.length-1];
              scan.loadScanAreaLayoutAsync().then((scanAreaLayout) => {
                let triggersIn = scanAreaLayout?.triggers;
                if (triggersIn && triggersIn.length > 0) {
                  setTriggers(triggersIn.map((trigger) => trigger.name));
                  setSelectedTrigger(triggersIn[0].name);
                  onChange(triggersIn[0].name);
                }     
              })
            }
        });
      }
    });
  }, []);  
    
  const handleTriggerSelectionChange = (event: SelectChangeEvent) => {
      setSelectedTrigger(event.target.value);
      onChange(event.target.value);
  };

  return (
    <FormControl variant="outlined" sx={sx}>
      <InputLabel id="trigger-select-label">Region</InputLabel>
      <Select
        labelId="trigger-select-label"
        value={selectedTrigger}
        onChange={handleTriggerSelectionChange}
        label="Trigger"
      >
        {triggers.map((entry, index) => (
          <MenuItem key={index} value={entry}>
            {entry}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TriggerPicker;
