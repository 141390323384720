
// Converts an HSL color value to RGB. Assumes h is contained in [0, 360) and s, l are contained in [0, 1] and

import { Color3 } from "@babylonjs/core";

// returns r, g, and b in the set [0, 255].
function hslToRgb(h: number, s: number, l: number): Color3 {
    let r: number, g: number, b: number;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return new Color3(r, g, b);
}

// Maps an integer to a unique, human-distinguishable color
export function integerToColor(id: number): Color3 {
    // Use the hue to generate distinct colors
    // Modulus 360 to ensure hue falls within a valid range
    const hue = id % 360;
    const saturation = 0.75; // High saturation for vivid colors
    const lightness = 0.5; // Middle lightness for brightness balance

    return hslToRgb(hue / 360, saturation, lightness);
}

export function encodeColor(color: Color3): string {
    // Convert each color component to a hex string, ensuring it is two characters long
    const toHex = (component: number) => Math.floor(component * 255).toString(16).padStart(2, '0');
  
    // Use the toHex helper to convert each component of the Color3 object to a hex string
    const rHex = toHex(color.r);
    const gHex = toHex(color.g);
    const bHex = toHex(color.b);
  
    // Concatenate the hex strings with a '#' prefix to form the full HTML color code
    return `#${rHex}${gHex}${bHex}`;
  };


 var cameraColorsTable = [
    new Color3(1,0,1), // from main entrance, magenta
    
    new Color3(0,0.35,0), // apparel wall, dark green
    new Color3(0,1,0), // apparel center, light green
    
    new Color3(0,0,1), // benches center, light blue
    new Color3(0,0,0.35), // benches wall, dark blue
    
    new Color3(0.35,0.35,0), // treadmills wall, dark yellow
    new Color3(1,1,0.5), // treacmills center, light yellow

    new Color3(0.35,0,0), // checkout wall, dark red
    new Color3(1,0,0), // checkout center, light red
 ];
 
export function cameraColors(idx: number): Color3 {
        return cameraColorsTable[idx];
}