import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { observer } from "mobx-react-lite";
import Address from "../models/Address";
import { useForm} from "react-hook-form";
import { SiteBionicsApplication, useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import Site from "../models/Site";
import { set } from "video.js/dist/types/tech/middleware";
import Account from "../models/Account";

interface CreateShadowSiteDialogProps {
  site: Site;
  onCancel: () => void;
  onCreate: (destAccount: Account, destSiteName: string) => void;
}

interface FormValues {
  destSiteName: string,
  destAccountId: string
}

const CreateShadowSiteDialog: React.FC<CreateShadowSiteDialogProps> = ({site, onCancel, onCreate}) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const [destAccountId, setDestAccountId] = useState<string>(site.account.accountId);
  const {register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {destSiteName: site.siteName + " Shadow", destAccountId: site.account.accountId},
    mode: "onBlur"
  });
      
  // When the cancel button is clicked
  const handleCancelEditClick = () => {
    onCancel();
  }

  // Prevents clicks on the backdrop from cancelation
  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason === "backdropClick") return;
    onCancel();
  }

  const handleSaveEditClick = (data: FormValues ) => {        
    // todo: how to use data.accountId in the list below?
    let destAccount = siteBionicsApplication.accountList?.find(account => account.accountId === destAccountId);
    if (destAccount) {
      onCreate(destAccount, data.destSiteName); 
    }
  };
    
  function handleAccountChange(event: SelectChangeEvent<string>): void {
    setDestAccountId(event.target.value);
  }

  return (
    <>    
      <Dialog open={true} onClose={handleCancelLocal} maxWidth="lg" >
        <DialogTitle>Create Shadow Site</DialogTitle>
        <form onSubmit={handleSubmit(handleSaveEditClick)}>
        <DialogContent>
          
        <TextField id="destSiteName" label="Site Name" margin="dense" type="string" fullWidth required
            {...register("destSiteName", { required: "Destination Site Name is required"})}
            error={!!(touchedFields["destSiteName"] && errors["destSiteName"])}
            helperText={touchedFields["destSiteName"] && errors.destSiteName?.message}   
          /> 

          <FormControl sx={{marginY: 1}}>
            <InputLabel id="account-list-label">Destination Account</InputLabel>
            <Select
              labelId="account-list-label"
              id="camera-list"
              value={destAccountId}
              label="Destination Account"            
              onChange={handleAccountChange}>
              {siteBionicsApplication.accountList?.map((account) => (
                <MenuItem key={account.accountId} value={account.accountId}>{account.accountName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        
        </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancelEditClick} color="secondary">Cancel</Button>
        <Button variant="outlined" disabled={!isValid} onClick={handleSubmit(handleSaveEditClick)} color="primary">Create</Button>
      </DialogActions>
      </form>
    </Dialog>      

</>
  )
};

export default observer(CreateShadowSiteDialog);