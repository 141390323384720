import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";

interface TimeDropdownProps {
  label?: string;
  name: string; 
  control: any;
}

const timeOptions = Array.from({ length: 49 }, (_, index) => index * 30); // every 30 mins

const TimeDropdown: React.FC<TimeDropdownProps> = ({ label, name, control }) => (
  <FormControl fullWidth margin="dense" variant="standard">
    {label &&
    <InputLabel>{label}</InputLabel>
    }
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select {...field} label={label}>
          {timeOptions.map((minutes) => (
            <MenuItem key={minutes} value={minutes}>
              {`${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(minutes % 60).padStart(2, '0')}`}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  </FormControl>
);

export default TimeDropdown;