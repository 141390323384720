import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSiteBionicsApplication } from "../../models/SiteBionicsApplication";
import TitleToolbar from "../../components/TitleToolbar";
import UserInfo from "../../models/UserInfo";
import ConfirmationDialog from "../../components/Confirm";

const AllUsers : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const [users, setUsers] = useState<UserInfo[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    siteBionicsApplication.service.fetchAllUsers().then((users) => {
      if (users === null) users = [];
      setUsers(users);
      setLoading(false);
    });
  }, [siteBionicsApplication]);

  return (
    <>
      <TitleToolbar title="All Users" sx={{ paddingLeft: "10pt", paddingRight: "10pt", marginTop: "10pt" }}>
        <>
          <Button variant="outlined" size="small" disabled={selectedUser === null} onClick={() => {setDeleteUserDialogOpen(true)}}>Delete</Button>
        </>
      </TitleToolbar>

      <Box component="div" sx={{ height: "10pt" }} />

      {loading ? "Loading..." :
        <>
          <TableContainer sx={{ paddingLeft: "10pt", paddingRight: "10pt" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>ToS Version</TableCell>
                  <TableCell>ToS Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((userInfo) => (
                  <TableRow key={userInfo.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={userInfo.id === selectedUser?.id}
                        onChange={() => setSelectedUser(userInfo.id === selectedUser?.id ? null : userInfo)}
                      />
                    </TableCell>
                    <TableCell>{userInfo.firstName} {userInfo.lastName}</TableCell>
                    <TableCell>{userInfo.email}</TableCell>
                    <TableCell>{userInfo.tosVersion}</TableCell>
                    <TableCell>{userInfo.tosDate ? userInfo.tosDate.toLocaleString() : ""}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {deleteUserDialogOpen && 
            <ConfirmationDialog title="Delete User" confirmationText="Are you sure you want to delete this user?" requiredInput={selectedUser!.email}
              onConfirm={() => {
                if (selectedUser) {
                  setUsers(users?.filter((u) => u.id !== selectedUser.id) ?? []);
                  setDeleteUserDialogOpen(false);
                  siteBionicsApplication.service.deleteUser(selectedUser.id);
                  setSelectedUser(null);
                }
              }}
              onClose={() => setDeleteUserDialogOpen(false)}
              />
          }
        </>
      }
    </>
  );
});

export default AllUsers;