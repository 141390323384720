import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"

import { Box, Typography } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import GeneralCard from "../cards/GeneralCard";
import HardwareCard from "../cards/HardwareCard";
import SiteUsersCard from "../cards/SiteUsersCard";
import AddressView from "../components/AddressView";
import TitleSimple from "../components/TitleSimple";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import { Videocam, Fullscreen, People, Storefront, Insights, ViewInAr, Hub, Radar, Dashboard } from '@mui/icons-material';

const SiteCards : FunctionComponent = observer(() => {
  
  const site = useSite();
  const account = site.account;
  
  const breadcrumbs = useSitePageBreadcrumbs("");
    

  function createSiteLink(page: string): string {
    return `/accounts/${account.accountId}/sites/${site.siteId}/${page}`;
  }

  useEffect(() => {
    account.loadRoleMapAsync();
  }, [account, account.roleMap]); 
  
  return (
    <Box component="div" display="flex" height="100%" flexDirection="column">

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleSimple title={site.siteName} sx={{paddingLeft: "10pt", paddingRight: "10pt"}}/>

      <Box component="div" height="10pt"/>          
    
      <Box component="div"  sx={{ overflowY: 'auto', display: 'flex', flexWrap: 'wrap', paddingX: "10pt"}}>
        
        <GeneralCard title="Site Information"
                    icon={<Storefront style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
                    moreLink={createSiteLink("info")}>
          <Box component="div" display="flex" flexDirection="column" sx={{gap: "10px"}}>
            <AddressView address={site.address} />
            {account.roleMap ?            
              <Typography>Roles: {account.roleMap.getSiteFriendlyRoleNames(account.baseSiteRoles.concat(site.siteRoles).filter((item, index, array) => array.indexOf(item) === index))} </Typography>          
            :
              <Typography>Loading...</Typography>
            }
          </Box>
          
        </GeneralCard>
        
        {site.hasSiteCapability("ViewSite") &&
          <>
            <GeneralCard title="3D Site Viewer" 
                        icon={<ViewInAr style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
                        moreLink={createSiteLink("viewer")}/>
            <GeneralCard title="View Video"
                        icon={<Fullscreen style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
                        moreLink={createSiteLink("video")}/>        
            <GeneralCard title="Site Activity" 
                        icon={<Insights style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
                        moreLink={createSiteLink("tracking")}/>
            <GeneralCard title="Site Dashboard" 
              icon={<Dashboard style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
              moreLink={createSiteLink("dashboard")}/>
  
            
          </>
        }
        {site.hasSiteCapability("UpdateSite") &&
            <HardwareCard account={account} site={site}/>                      
        }
        {site.hasSiteCapability("ManageSiteUsers") &&
            <SiteUsersCard account={account} site={site}/>
        }
      
      </Box>

    </Box>
  )
})

export default SiteCards; 