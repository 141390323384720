import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import BreadcrumbBar from "../components/BreadcrumbBar";
import Hubs from "./Hubs";
import Cameras from "./Cameras";
import { Box } from '@mui/material';
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import useSiteHubStatuses from "../hooks/useSiteHubStatuses";

const Hardware : FunctionComponent = observer(() => {
 
  const breadcrumbs = useSitePageBreadcrumbs("Hardware");
  const site = useSite();
  const [hubStatuses, color] = useSiteHubStatuses(site.account.accountId, site);

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs}/> 
      
      <Box component="div" sx={{display: "flex", flexDirection: "column", flexGrow: "1", overflow: "auto", minHeight: "800px"}}>
        <Hubs hubStatuses={hubStatuses}></Hubs>

        <Box component="div" sx={{height: "20px"}}/>
          
        <Cameras hubStatuses={hubStatuses}></Cameras>            
      </Box>
    </>
  )
})

export default Hardware;