import { useState } from "react";
import {Paper, Box, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputLabel, Select } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { observer } from "mobx-react-lite";
import Address from "../models/Address";
import { useForm, Controller} from "react-hook-form";
import TimeDropdown from "../components/TimeDropdown"; 
import AddressFormFields from "../components/AddressFormFields";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

interface SiteDialogProps {

  title: string;

  name?: string;

  timeZone: string;

  openHours: number[];

  address?: Address;

  saveLabel?: string;

  onClose: () => void;

  onSave: (name: string, timeZone: string, openHours: number[], address: Address) => void;
}

interface FormValues {
  name: string;

  timeZone: string;

  openHours: number[];

  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

const SiteDialog: React.FC<SiteDialogProps> = ({
    title,
    name = "",
    timeZone,
    openHours,
    address = new Address("", "", "", "", "", ""),
    saveLabel = "Save",
    onClose,
    onSave
 }) => {
  
  const {control, watch, register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      name: name,
      timeZone: timeZone,
      openHours: openHours,
      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country
    },
    mode: "onBlur"
  });
      
  // When the cancel button is clicked
  const handleCancelEditClick = () => {
    onClose();
  }

  // Prevents clicks on the backdrop from cancelation
  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    
    if (reason === "backdropClick") return;

    onClose();

  }

  const handleSaveEditClick = (data:FormValues ) => {        
    const address = new Address(data.street1, data.street2, data.country, data.city, data.state, data.postalCode);
    onSave(data.name, data.timeZone, data.openHours, address);    
  };
    
  const stateAbbreviations = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", 
    "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", 
    "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];
  
  const aiv = errors["street1"] || errors["street2"] || errors["city"] || errors ["state"] || errors["postalCode"] || errors["country"];

  return (
    <>    
      <Dialog open={true} onClose={handleCancelLocal} maxWidth="lg" >
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSaveEditClick)}>
        <DialogContent>
          <Paper elevation={2} sx={{ padding: 2, marginTop: 2, backgroundColor: 'transparent'}}>
            <TextField 
              id="name" 
              label="Site Name"
              margin="dense" 
              type="string" 
              variant="standard"
              required
              fullWidth 
              {...register("name", { required: "Site name is required"})}
              error={!!(touchedFields["name"] && errors["name"])}
              helperText={touchedFields["name"] && errors.name?.message}                        
            />
          </Paper>
          <Paper elevation={2} sx={{ padding: 2, marginTop: 2, backgroundColor: 'transparent' }}>
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel id="timeZone-label">Time Zone</InputLabel>
              <Controller
                name="timeZone"
                control={control}
                render={({field}) => (
                  <Select labelId="timeZone-label" id="timeZone-select" label="Time Zone" 
                    {...register("timeZone")}
                    value={watch("timeZone")}
                  >
                    <MenuItem value="Pacific/Honolulu">Hawaian</MenuItem>
                    <MenuItem value="America/Anchorage">Alaska</MenuItem>
                    <MenuItem value="America/Los_Angeles">Pacific</MenuItem>
                    <MenuItem value="America/Denver">Mountain</MenuItem>
                    <MenuItem value="America/Phoenix">Mountain (no DST)</MenuItem>
                    <MenuItem value="America/Chicago">Central</MenuItem>
                    <MenuItem value="America/New_York">Eastern</MenuItem>
                  </Select>              
                )}
              />              
            </FormControl>
          </Paper>
          
          <Box component="div" sx={{height: 20}}/> 

          <Accordion sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <InputLabel sx={{ fontSize: "0.875rem", color: "text.secondary" }}>
                Site Hours
              </InputLabel>
            </AccordionSummary>
            <AccordionDetails>
          
              <TableContainer>
              <Table size="small" sx={{ "& .MuiTableCell-root": { padding: "4px 8px", fontSize: "0.875rem" } }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Day</TableCell>
                      <TableCell>Open Time</TableCell>
                      <TableCell>Close Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {daysOfWeek.map((day, index) => (
                      <TableRow key={day}>
                        <TableCell>{day}</TableCell>
                        <TableCell>
                          <TimeDropdown
                            name={`openHours[${index * 2}]`}
                            control={control}
                          />
                        </TableCell>
                        <TableCell>
                          <TimeDropdown
                            name={`openHours[${index * 2 + 1}]`}
                            control={control}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>   

          <Box component="div" sx={{height: 20}}/>                                     

          <Accordion sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {aiv ? 
              <InputLabel sx={{ fontSize: "0.875rem", color: "red" }}>
              Site Address (errors)
            </InputLabel>
              :
              <InputLabel sx={{ fontSize: "0.875rem", color: "text.secondary" }}>
                Site Address
              </InputLabel>
              }
            </AccordionSummary>
            <AccordionDetails>
              <AddressFormFields
                control={control}
                errors={errors}
                touchedFields={touchedFields}
                register={register}
              />
          
            </AccordionDetails>
          </Accordion>
        </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancelEditClick} color="secondary">Cancel</Button>
        <Button variant="outlined" disabled={!isValid} onClick={handleSubmit(handleSaveEditClick)} color="primary">{saveLabel}</Button>
      </DialogActions>
      </form>
    </Dialog>      

</>
  )
};

export default observer(SiteDialog);