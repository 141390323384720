import React, {ReactNode, ReactElement} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { BusinessCenter } from '@mui/icons-material';

interface GeneralCardProps {
  icon?: ReactElement;
  title: string;
  moreLink: string;
  children?: ReactNode;
}

const GeneralCard: React.FC<GeneralCardProps> = ({ icon, title, moreLink, children }) => {
  function getFinalPathSegment(urlString: string): string | null {
    try {
      const url = new URL(urlString, 'http://example.com'); // The base URL doesn't matter to this use case.
      const pathSegments = url.pathname.split('/').filter(segment => segment.length > 0);
      if (pathSegments.length === 0) {
        return null; // No path segments in the URL
      }
      return pathSegments[pathSegments.length - 1];
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }

  let navigate = useNavigate();
  return (
    <Card sx={{ width: "300px", height: "300px", margin: 2 }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box component="div" sx={{ display: 'flex', alignItems:'center', gap: "10px"}}>
        {icon && <>{icon}</>}        
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        </Box>
        <Box component="div" sx={{ display: 'flex', flexGrow: 1, marginTop: 2 }}>
          {children ? children : <></>}
        </Box>
        <Typography variant="body2" color="primary" align="right" sx={{ marginTop: 2 }}>
          <Link onClick={(_e) => navigate(moreLink)} data-testid={'card-more-link-'+getFinalPathSegment(moreLink)}>
            More
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default GeneralCard;